import React from "react";
import {
  faDashboard,
} from "@fortawesome/free-solid-svg-icons";
import { ACTIVE } from "../utils/constants";
const Builder = React.lazy(() =>
  import("../pages/AdminModule/Builder/AddBuilder")
);
const ListBuilder = React.lazy(() => import("../pages/AdminModule/Builder"));
const ViewBuilder = React.lazy(() =>
  import("../pages/AdminModule/Builder/ViewBuilder")
);

const Dashboard = React.lazy(() => import("../pages/Dashboard"));

export const BuilderRoutes = [
  {
    name: "Dashboard",
    path: "/",
    mainMenu: true,
    className: ACTIVE,
    icon: faDashboard,
    subMenuHead: false,
    component: <Dashboard crumbs="Dashboard" />,
  },
  // {
  //   name: "BUILDER",
  //   component: <ListBuilder crumbs="Builder" />,
  //   //  role: builder,
  //   mainMenu: true,
  //   path: "/list-builder",
  // },

  // {
  //   name: "ADD_BUILDER",
  //   component: <Builder crumbs="Builder/Add" />,
  //   path: "/add-builder",
  //   className: ACTIVE,
  // },
  // {
  //   name: "LIST_BUILDER",
  //   component: <ListBuilder crumbs="Builder/List" />,
  //   path: "/list-builder",
  // },
  // {
  //   name: "VIEW_BUILDER",
  //   component: <ViewBuilder crumbs="Builder/View" />,
  //   path: "/view-builder",
  // },
];
