import { pagination } from "../../../utils/constants";

const API_ENDPOINT = "/registration";

export const addRegistration = (build) => {
  return build.mutation({
    query: ({ data }) => ({
      url: `${API_ENDPOINT}`,
      method: "POST",
      body: data,
    }),
  });
};

export const fetchRegistration = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `${API_ENDPOINT}/vo/list?${params.join("&")}`;
      return url;
    },
  });
};

export const fetchRegistrationById = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `${API_ENDPOINT}/${id}`;
      return url;
    },
  });
};

export const updateRegistration = (build) => {
  return build.mutation({
    query: ({ id, data }) => ({
      url: `${API_ENDPOINT}/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
};

export const deleteRegistration = (build) => {
  return build.mutation({
    query: ({ id }) => ({
      url: `${API_ENDPOINT}/${id}`,
      method: "DELETE",
    }),
  });
};

export const fetchPendingRegistration = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `${API_ENDPOINT}/pending/list?${params.join("&")}`;
      return url;
    },
  });
};

export const addRegistrationProcess = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `${API_ENDPOINT}/submit/${id}`,
      method: "POST",
    }),
  });

export const approveRegistrationProcess = (build) => {
  return build.mutation({
    query: ({ id, data }) => ({
      url: `${API_ENDPOINT}/approve/${id}`,
      method: "POST",
      body: data,
    }),
  });
};

export const approveRegistrationProcessWithoutSurveyor = (build) => {
  return build.mutation({
    query: ({ id, data }) => ({
      url: `${API_ENDPOINT}/approve/withoutsurveyor/${id}`,
      method: "POST",
      body: data,
    }),
  });
};

export const rejectRegistrationProcess = (build) => {
  return build.mutation({
    query: ({ id, data }) => ({
      url: `${API_ENDPOINT}/reject/${id}`,
      method: "POST",
      body: data,
    }),
  });
};
export const fetchQR = (build) => {
  return build.query({
    query: (data) => {
      const url = `/qrcode/generate?data=${data}`;
      return url;
    },
  });
};