import { Config } from "../config";
import UserService from "../services/UserService";

const uploadFilesApi = async (body) => {
  const token = UserService.getToken();
  return fetch(`${Config.MASTER_SERVICE_API}/files/upload`, {
    headers: { authorization: `Bearer ${token}` },
    method: "POST",
    body: body,
  })
    .then((res) => res?.json())
    .catch((e) => {
      console.error(e);
      return null;
    });
};

async function uploadFile(file) {
  const formData = new FormData();
  formData.append("file", file);
  const response = await uploadFilesApi(formData);
  if (response && [201].includes(response.statusCode)) {
    const [{ filename }] = response.result;
    return filename;
  }
  throw new Error("upload failed");
}

async function uploadFilesInSchema(obj) {
  const result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let value = obj[key];
      if (value instanceof File) {
        value = await uploadFile(value);
      } else if (Array.isArray(value)) {
        value = await Promise.all(
          value.map(async (arrayItem) => {
            if (arrayItem instanceof File) {
              return await uploadFile(arrayItem);
            } else if (typeof arrayItem === "object") {
              return await uploadFilesInSchema(arrayItem);
            } else {
              return arrayItem;
            }
          })
        );
      } else if (typeof value === "object") {
        value= await uploadFilesInSchema(value);
      }
      result[key] = value;
    }
  }

  return result;
}

export { uploadFilesInSchema };
