import { Accordion, Col, FormControl, Table } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";

const Remarks = ({ remarks }) => {
  return (
    <Container className="dashboard-container">
      {remarks && remarks.length > 0 && (
        <div className="d-flex flex-column gap-4 card">
          <Accordion defaultActiveKey={["0"]} alwaysOpen flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Remarks</Accordion.Header>
              <Accordion.Body>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th style={{ width: "300px" }}>User Name</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {remarks.map((value, index) => (
                      <tr key={index}>
                        <td>{value.username}</td>
                        <td>{value.remark}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </Container>
  );
};

export default Remarks;
