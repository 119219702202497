import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

function Buttons({
  className,
  onClick,
  label,
  variant = "primary",
  disabled = false,
  type,
}) {
  return (
    <Button
      className={className}
      onClick={onClick}
      type={type}
      variant={variant}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

Buttons.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.any,
  className: PropTypes.any,
  type: PropTypes.any,
};

export default Buttons;

// backgroundColor: "rgba(193,40,114,1)",
