import React from "react";
import { Dropdown, Table } from "react-bootstrap";
import "./style.css";
import { statusStyle } from "./TableStatusStyle";
// import { downloadFile, statusOption } from "../utils/constants";
// import view from "../assets/view.png";
import { pagination } from "../utils/constants";
// import { IoMdEye } from "react-icons/io";
import {
  MdDelete as DeleteIcon,
  MdDataThresholding,
  MdToggleOff,
  MdToggleOn,
} from "react-icons/md";
import { MdRemoveRedEye as ViewIcon } from "react-icons/md";
import { MdMoreVert as MoreIcon } from "react-icons/md";

// import { isContentEditable } from "@testing-library/user-event/dist/utils";

function CommonListTable({ rowHead, data, url, page }) {
  const TableHead = ({ rowHead }) => {
    return (
      <tr>
        {rowHead.map((item) => (
          <th className="p-3 border-0 table-list-heading" key={item.id}>
            {item.heading}
          </th>
        ))}
      </tr>
    );
  };

  const TableBody = ({ data, rowHead, url, index }) => {
    const getFieldValue = (item, data) => {
      var dataValue = "";
      if (item.field === "status") {
        dataValue = data[item.field] === true ? "Active" : "InActive";
      } else if (item.field === "taxApplicable") {
        dataValue = data[item.field] === true ? "Yes" : "No";
      } else if (item.field === "slno") {
        dataValue = Number((page - 1) * pagination.limit + index + 1);
      } else if (item.type === "json") {
        dataValue = data[item.field] ? data[item.field][item.key] : "";
      } else if (item.field === "processStatus") {
        dataValue = data[item.field] ? data[item.field] : "Draft";
      } else if (item.field === "vesselStatus") {
        dataValue = data[item.field] ? data[item.field] : "Draft";
      } else if (item.field === "stagestatus") {
        dataValue = data[item.field] ? data[item.field] : "Draft";
      } else if (item.field === "surveyProcessStatus") {
        dataValue = data[item.field] ? data[item.field] : "Draft";
      } else if (item.field === "registrationStatus") {
        dataValue = data[item.field] ? data[item.field] : "Draft";
      } else if (item.field === "carvingStatus") {
        dataValue = data[item.field] ? data[item.field] : "Draft";
      } else if (item.field === "submittedOn") {
        const submittedDate = new Date(data[item.field]);
        const day = submittedDate.getDate().toString().padStart(2, "0");
        const month = (submittedDate.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const year = submittedDate.getFullYear().toString().slice(-2); // Get last two digits of the year
        dataValue = `${day}-${month}-${year}`;
      } else if (item.field === "vesselType") {
        dataValue = data["vesselTypeName"] ? data["vesselTypeName"] : "Draft";
      } else if (item.field === "vesselCategory") {
        dataValue = data["vesselCategoryName"];
      } else if (item.field === "vesselTypeId") {
        dataValue = data[item.field]?.vesselTypeName;
      } else if (item.field === "role") {
        dataValue = data[item.field]?.roleName;
      } else if (item.field === "surveyProcessStatus") {
        dataValue = data[item.field]?.surveyProcessStatus;
      } else if (item.field === "district") {
        dataValue = data[item.field]?.districtName;
      } else {
        dataValue = data[item.field];
      }

      console.log(item.field, data[item.field]);

      return (
        <p
          style={statusStyle(item, {
            Status:
              item.field === "processStatus" ||
              item.field === "vesselStatus" ||
              item.field === "surveyProcessStatus" ||
              item.field === "registrationStatus" ||
              item.field === "stagestatus" ||
              item.field === "carvingStatus"
                ? data[item.field] || "Draft"
                : "",
          })}
        >
          {dataValue}
        </p>
      );
    };

    return (
      <tr className="table-list-row">
        {rowHead.map((item) => (
          <td className="table-list-data" key={item.id}>
            {item.heading === "Action" || item.heading === "View" ? (
              item.actions ? (
                <div className="d-flex gap-2">
                  {item.actions.map((action, key) => (
                    <ActionButton key={key} data={data} {...action} />
                  ))}
                </div>
              ) : (
                <ActionButton data={data} type="View" onClick={item.onClick} />
              )
            ) : item.heading === "More" ? (
              <MoreButton
                options={item.options}
                data={data}
                metaOptions={item.metaOptions}
              />
            ) : (
              getFieldValue(item, data, index)
            )}
          </td>
        ))}
      </tr>
    );
  };
  return (
    <Table className="common-list-table" striped bordered hover>
      <thead className="list-heading">
        <TableHead rowHead={rowHead} />
      </thead>
      <tbody>
        {data.map((item, index) => (
          <TableBody
            key={index}
            data={item}
            rowHead={rowHead}
            url={url}
            index={index}
          />
        ))}
      </tbody>
    </Table>
  );
}

function MoreButton({ options, data, metaOptions }) {
  const status =
    data.processStatus ||
    data.vesselStatus ||
    data.surveyProcessStatus ||
    data.registrationStatus ||
    data.carvingStatus ||
    data.stagestatus;
  const dropdownOptions = options[status] || [];

  if (metaOptions) {
    metaOptions(data).forEach((option) => {
      dropdownOptions.unshift(option);
    });
  }

  const hasDropdown = () => dropdownOptions.length > 0;

  return (
    <Dropdown drop="start">
      <Dropdown.Toggle className="more-icon" disabled={!hasDropdown()}>
        <MoreIcon size={"1.125rem"} color="#CBC9C9"></MoreIcon>
      </Dropdown.Toggle>

      {hasDropdown() ? (
        <Dropdown.Menu className="shadow-sm">
          {dropdownOptions.map((option) => {
            return (
              <Dropdown.Item onClick={() => option.onClick(data)}>
                {option.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      ) : null}
    </Dropdown>
  );
}

function ActionButton({ type, onClick, data }) {
  const toggleType = data?.status ? "ToggleOn" : "ToggleOff";
  return (
    <button
      className="table-action-btn btn-toggle"
      onClick={() => onClick(data)}
    >
      {type === "Toggle" ? <Icon type={toggleType} /> : <Icon type={type} />}
    </button>
  );
}

function Icon({ type, alt }) {
  let iconComponent;
  let iconAlt = alt || ""; // Set alt text or leave it blank if not provided

  switch (type) {
    case "View":
      iconComponent = <ViewIcon size={"1.125rem"} />;
      iconAlt = iconAlt || "View Icon"; // Set default alt text if alt prop is not provided
      break;
    case "Delete":
      iconComponent = <DeleteIcon size={"1.125rem"} />;
      iconAlt = iconAlt || "Delete Icon"; // Set default alt text if alt prop is not provided
      break;
    case "ToggleOn":
      iconComponent = (
        <MdToggleOn
          style={{ fontSize: "1.55rem" }}
          className="custom-toggle-icon"
        />
      );
      iconAlt = iconAlt || "Toggle Icon"; // Set default alt text if alt prop is not provided
      break;
    case "ToggleOff":
      iconComponent = (
        <MdToggleOff style={{ color: "grey", fontSize: "1.55rem" }} />
      );
      iconAlt = iconAlt || "Toggle Icon"; // Set default alt text if alt prop is not provided
      break;
    default:
      iconComponent = <></>;
      break;
  }

  return (
    <span className="icon-wrapper" alt={iconAlt}>
      {iconComponent}
    </span>
  );
}

/*const actionButtons = (data) => {
  return data.icon.map((dataItem, index) => (
    <button
      className="table-action-btn"
      key={data.value}
      // onClick={}
    >
      <img src={dataItem} className="table-edit-icon" alt="Action Icon" />
      {data.button_Status}
    </button>
  ));
};*/

export default CommonListTable;
