import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import "./style.scss";
import warning from "../assets/warning.png";
import tickpick from "../assets/tick.png";

const CommonConfirmationModal = ({
  show,
  handleClose,
  handleConfirm,
  successIcon = false,
  title,
  description,
  cancelLabel,

  confirmLabel,
}) => {
  return (
    <>
      <Modal show={show} className="confirmation-modal" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{""}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="success-modal-body">
          {successIcon ? (
            <img src={tickpick} alt="Correct" />
          ) : (
            <img
              src={warning}
              className="common-warning-image"
              alt={"Warning"}
            />
          )}

          {description}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-outline" onClick={handleClose}>
            {cancelLabel}
          </Button>

          <Button className="btn btn-default" onClick={handleConfirm}>
            {confirmLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonConfirmationModal;
