import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  fetchSurveyTypes,
  fetchSurveyTypebyId,
  addSurveyType,
  updateSurveyType,
  deleteSurveyType,
} from "./SurveyTypes";

export const SurveyTypesApi = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    fetchSurveyTypes: fetchSurveyTypes(build),
    fetchSurveyTypebyId: fetchSurveyTypebyId(build),
    addSurveyType: addSurveyType(build),
    updateSurveyType: updateSurveyType(build),
    deleteSurveyType: deleteSurveyType(build),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchSurveyTypesQuery,
  useLazyFetchSurveyTypebyIdQuery,
  useAddSurveyTypeMutation,
  useUpdateSurveyTypeMutation,
  useDeleteSurveyTypeMutation,
} = SurveyTypesApi;
