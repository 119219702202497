import _kc from "../../src/keycloak";
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
    })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

//const doLogout = _kc.logout;
const doLogout = () => {
  _kc.logout({ redirectUri: window.location.origin });
};

const getToken = () => {
  return _kc.token;
  //return "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJJZWhfdGtaVEZnSUE4ZC1oczQ1bjVqR2RJNlF5OVZ3a3NnRXZyVTdsbE9RIn0.eyJleHAiOjE3MDIzMDEyMTQsImlhdCI6MTcwMjI4MzIxNCwianRpIjoiMjdiNWQyODItZjJkNy00ZTVkLWE1YTUtODljYTM3MmNlNzlmIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrL3JlYWxtcy9rbWItbWFpbiIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiIyYzFmMTY3MC0yOThhLTRmOTAtOWExYy0yYTc2MGMwMWE1YTUiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJrbWItbWljcm9zZXJ2aWNlIiwic2Vzc2lvbl9zdGF0ZSI6IjhlZjMzYzFjLTc3ODUtNGQxMy1iZDUzLTJjMDA0N2FkYTBjMCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1rbWItbWFpbiIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJrbWItbWljcm9zZXJ2aWNlIjp7InJvbGVzIjpbImFkbWluIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6IkN1c3RvbV9Ub2tlbl9DbGFpbXMgcHJvZmlsZSBlbWFpbCByb2xlcyIsInNpZCI6IjhlZjMzYzFjLTc3ODUtNGQxMy1iZDUzLTJjMDA0N2FkYTBjMCIsImNsaWVudF9yb2xlcyI6IlttYW5hZ2UtYWNjb3VudCwgbWFuYWdlLWFjY291bnQtbGlua3MsIHZpZXctcHJvZmlsZV0iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicHJlZmVycmVkX3VzZXJuYW1lIjoic3VwZXJhZG1pbnVzZXIiLCJlbWFpbCI6InN1cGVyYWRtaW51c2VyQHVsdHMuaW4ifQ.Txc5syIT_wjE5sYhL6F1MSe8vL0npMqm-0hZvaGjnP1FJVe07ZmEv4rzV6UC8p6ZE-V1Qof3E7_WndSHLuBQM5Aq5lQ28BxPt43eUC-4a7mCXk6XrXtrusPbMy27RK9ZoGjeACxHeoSSL_L3vbNypZo0bgtedD3lhFcpEmqCYNIIaxr1gnhM6pjdB3s8U1k8Qje93SWPpzZWwYtHbUjlO36HaPoaICCQOvj5leKPtIVzXKJYBx24myZD4ianPaFK13OCtIRFZCbCVbj18tvSq05Ugg8gA697p3xp0Npya6vxA_oUHsfvaghFP2kJewiwd-RqC0wrd2CN-10er7Kj4w";
};

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

//const updateToken = (successCallback) =>
//  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));
const getUserInfo = async () => {
  const userInfo = await _kc.loadUserInfo();
  return userInfo;
};
const getUserProfile = async () => {
  const userProfile = await _kc.loadUserProfile();
  return userProfile;
};
const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  getUserInfo,
  getUsername,
  hasRole,
  getUserInfo,
  getUserProfile,
};

export default UserService;
