import MasterServiceAPI from "../../MasterServiceAPI";
import {
  addCarving,
  approveCarvingProcess,
  deleteCarving,
  fetchCarving,
  fetchCarvingById,
  fetchPendingCarving,
  rejectCarvingProcess,
  submitCarvingProcess,
  updateCarving,
} from "./carving";

export const CarvingAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addCarving: addCarving(build),
    fetchCarving: fetchCarving(build),
    fetchCarvingById: fetchCarvingById(build),
    updateCarving: updateCarving(build),
    deleteCarving: deleteCarving(build),
    fetchPendingCarving: fetchPendingCarving(build),
    submitCarvingProcess: submitCarvingProcess(build),
    approveCarvingProcess: approveCarvingProcess(build),
    rejectCarvingProcess: rejectCarvingProcess(build),
  }),
  overrideExisting: false,
});

export const {
  useAddCarvingMutation,
  useLazyFetchCarvingQuery,
  useLazyFetchCarvingByIdQuery,
  useUpdateCarvingMutation,
  useDeleteCarvingMutation,
  useLazyFetchPendingCarvingQuery,
  useSubmitCarvingProcessMutation,
  useApproveCarvingProcessMutation,
  useRejectCarvingProcessMutation,
} = CarvingAPI;
