import React from "react";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const Breadcrumb = ({ crumbs }) => {
  if (!crumbs) {
    return null; // Or any default breadcrumb content or message
  }

  const breadcrumbs = crumbs
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index) => {
      const isLast = index === crumbs.length - 1;
      return (
        <span key={index} className="inactive">
          {crumb.replace(/%20/g, " ")}{" "}
          {!isLast && <FontAwesomeIcon icon={faAngleRight} />}{" "}
        </span>
      );
    });
  return (
    <Row className="breadcrum">
      <div>
        <p>{breadcrumbs}</p>
      </div>
    </Row>
  );
};

export default Breadcrumb;
