const API_ENDPOINT = "/otp";

export const getOtp = (build) => {
  return build.query({
    query: ({ data }) => ({
      url: API_ENDPOINT,
      method: "POST",
      body: data,
    }),
  });
};

export const verifyOtp = (build) => {
  return build.mutation({
    query: ({ data }) => ({
      url: `${API_ENDPOINT}/verify`,
      method: "POST",
      body: data,
    }),
  });
};
