import Form from "react-bootstrap/Form";
import React from "react";
import PropTypes from "prop-types";
import "./style.css";
function CheckBox({
  id,
  label,
  onChange,
  value,
 
  isDisabled,
  checked,
  required = false,
}) {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "90px" }}>
      <Form.Check
        type={"checkbox"}
        id={id}
        label={
          <span>
            {label}
            {required && <span className="required-indicator"></span>}
          </span>
        }
        onChange={onChange}
        isValid={false}
        value={value}
        checked={checked}
        disabled={isDisabled}
      />
    </div>
  );
}

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckBox;
