import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faUser,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { setLocalStorageItem } from "../../utils/utility";
import { useLazyFetchLoggedUserQuery } from "../../services/modules/admin/users";
import { Config } from "../../config";
import { useLazyDownloadFileQuery } from "../../services/modules/common/file";

function Navbar() {
  const navigate = useNavigate();
  const [token, setToken] = useState(UserService.getToken);
  const [fullName, setFullName] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [
    fetchLoggedUser,
    {
      data: userData,
      isSuccess: isUserDataSuccess,
      isLoading: isUserDataLoading,
    },
  ] = useLazyFetchLoggedUserQuery();

  useEffect(() => {
    fetchLoggedUser({});
  }, [token, fetchLoggedUser]);

  const [downloadFile] = useLazyDownloadFileQuery();

  useEffect(() => {
    const name = userData?.result[0]?.firstName;
    setFullName(name);
  }, [userData]);

  useEffect(() => {
    const fileName = userData?.result[0]?.profilePic;
    const fetchData = async () => {
      if (fileName) {
        try {
          const response = await downloadFile({
            fileName: fileName,
          });

          setProfilePic(response.data);
        } catch (error) {
          // Handle errors if necessary
          console.error("Error fetching data:", error);
        }
      } else {
      }
    };

    fetchData();
  }, [userData]);
  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a className="nav-item nav-link px-0 me-xl-4">
          <i className="bx bx-menu bx-sm"></i>
        </a>
      </div>
      <div
        className="navbar-nav-right d-flex align-items-end"
        id="navbar-collapse"
      >
        <div className="navbar-nav align-items-center">
          <div className="nav-item d-flex align-items-center">
            <i className="bx bx-search fs-4 lh-0"></i>
          </div>
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item navbar-dropdown dropdown-user dropdown user-profile">
              <a
                className="nav-link dropdown-toggle hide-arrow user-profile-img"
                data-bs-toggle="dropdown"
              >
                {isUserDataSuccess && userData.result.length > 0 ? ( // Check if userData exists and contains the expected structure
                  <div className="avatar avatar-online">
                    <img
                      className="w-px-40 h-auto rounded-circle"
                      src={
                        userData.result[0].profilePic
                          ? profilePic
                          : "../../assets/icons/profile.png"
                      }
                    />
                  </div>
                ) : null}
              </a>
            </li>
            <li>
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  {fullName ? fullName : null}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <li>
                    <a
                      class="dropdown-item"
                      onClick={() => navigate("/my-profile")}
                    >
                      <FontAwesomeIcon icon={faUser} />
                      My Profile
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <FontAwesomeIcon icon={faGear} />
                      Settings
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() => {
                        UserService.doLogout();
                        localStorage.clear();
                      }}
                    >
                      <FontAwesomeIcon icon={faRightFromBracket} />
                      Logout
                    </a>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
