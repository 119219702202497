import { combineReducers } from "redux";
import MasterServiceAPI from "../services/MasterServiceAPI";
import LoadingSlice from "../services/LoadingSlice";
import UserSlice from "../services/modules/admin/users/userSlice";
import * as modules from "../services/modules";

const rootReducer = combineReducers({
  [MasterServiceAPI.reducerPath]: MasterServiceAPI.reducer,
  loadingSlice: LoadingSlice,
  userSlice: UserSlice,

  ...Object.values(modules).reduce(
    (acc, module) => ({
      ...acc,
      [module.reducerPath]: module.reducer,
    }),
    {}
  ),
});

export default rootReducer;
