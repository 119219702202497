import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import MasterServiceAPI from "../services/MasterServiceAPI";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([MasterServiceAPI.middleware]);

    if (process.env.NODE_ENV === "development") {
      middleware = middleware.concat(logger);
    }
    return middleware;
  },
});
export default store;
