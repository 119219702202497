import { Accordion, Container } from "react-bootstrap";
import VesselDetails from "./VesselDetails";
import RegistrationDetails from "./RegistrationDetails";
import { useState } from "react";
import SurveyDetails from "./SurveyDetails";
import "./style.scss";
import VesselDocuments from "./VesselDocuments";
import StabilityDocuments from "./StabilityDocuments";


function CommonVesselDetails({
  vesselId = null,
  registrationId = null,
  defaultActiveKey = ["0"],
}) {
  const [vesselData, setVesselData] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [stabilityDcouments,setStabilityDocuments]=useState(null);

  return (
    <Container className="dashboard-container">
      <div className="common-vessel-details">
        <Accordion
          // defaultActiveKey={defaultActiveKey}
          alwaysOpen
          flush
          className="d-flex flex-column gap-3"
        >
          {vesselId ? (
            <VesselDetails
              vesselId={vesselId}
              setVesselData={setVesselData}
              setAdditionalData={setAdditionalData}
            />
          ) : null}

          {vesselId ? (
            <>
            <VesselDocuments vesselDataAdditional={additionalData}  />
            <StabilityDocuments vesselId={vesselId}/>
            </>
          ) : null}
          
          {additionalData ? (
            <SurveyDetails
              vesselData={vesselData}
              additionalData={additionalData}
            />
          ) : null}
          {registrationId ? (
            <RegistrationDetails registrationId={registrationId} />
          ) : null}
        </Accordion>
      </div>
    </Container>
  );
}

export default CommonVesselDetails;
