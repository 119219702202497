import { pagination } from "../../../../utils/constants";

export const fetchSurveyTypes = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/survey-types?${params.join("&")}`;
      return url;
    },
   
  });
};
export const fetchSurveyTypebyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/survey-types/${id}`;
      return url;
    },
  });
};

export const addSurveyType = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/survey-types`,
      method: "post",
      body: data,
    }),
  });
export const updateSurveyType = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `survey-types/${id}`,
      method: "PATCH",
      body: data,
    }),
   
  });
export const deleteSurveyType = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `survey-types/${id}`,
    }),
  });
