import React from "react";
import ReactPaginate from "react-paginate";
import { pagination } from "../utils/constants";

const Pagination = ({ pageCount, handlePageChange, ...props }) => {
  const itemPerPage = Math.ceil(pageCount / pagination.limit);

  if (pageCount <= pagination.limit) return <></>;
  return (
    <div className="pagination-container">
      <ReactPaginate
        breakLabel="..."
        nextLabel=" >"
        previousLabel="< "
        pageCount={itemPerPage}
        onPageChange={handlePageChange}
        pageRangeDisplayed={pagination.limit}
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        breakClassName={"page-item"}
        activeClassName={"active"}
        {...props}
      />
    </div>
  );
};

export default Pagination;
