export const statusStyle = (item, data) => {
  if (item.heading === "Status") {
    const status = data.Status || "";
    switch (status) {
      case "Submitted":
      case "Requested":
      case "Survey Submitted":
      case "Registration Submitted":
      case "Carving Submitted":
        return {
          background: "#1890FF29",
          color: "#3D5CB8",
          borderRadius: "6px",
          fontWeight: "700",
          // maxWidth: "78px",
          // maxHeight: "22px",
          textAlign: "center",
          padding: "1px, 8px, 1px, 8px",
          fontSize: "12px",
          lineHeight: "20px",
          alignItems: "center",
        };
      case "Under Processing":
      case "Survey Pending":
      case "Registration Pending":
      case "Pending":
        return {
          background: "#FFAA0429",
          color: "#FFAA04",
          borderRadius: "6px",
          fontWeight: "700",
          // maxWidth: "119px",
          // maxHeight: "22px",
          textAlign: "center",
          padding: "1px, 8px, 1px, 8px",
          fontSize: "12px",
          lineHeight: "20px",
          alignItems: "center",
        };
        case "Informed":
        return {
          background: " #D9B580",
          color: " #000000",
          borderRadius: "6px",
          fontWeight: "700",
          // maxWidth: "119px",
          // maxHeight: "22px",
          textAlign: "center",
          padding: "1px, 8px, 1px, 8px",
          fontSize: "12px",
          lineHeight: "20px",
          alignItems: "center",
        };
      case "Approved":
      case "Stage Verification Approved":
      case "Survey Approved":
      case "Registration Approved":
      case "Carving Approved":
      case "Issued":
      case "Done":
        return {
          background: "#54D62C29",
          color: "rgb(4 110 29)",
          borderRadius: "6px",
          fontWeight: "700",
          // maxWidth: "78px",
          // maxHeight: "22px",
          textAlign: "center",
          padding: "1px, 8px, 1px, 8px",
          fontSize: "12px",
          lineHeight: "20px",
          alignItems: "center",
        };
      case "Draft":
      case "Survey Drafted":
      case "Registration Drafted":
      case "Carving Drafted":
      case "Not Applied":
        return {
          background: "#454F5B29",
          color: "#5A5C68",
          borderRadius: "6px",
          fontWeight: "700",
          // maxWidth: "78px",
          // maxHeight: "22px",
          textAlign: "center",
          padding: "1px, 8px, 1px, 8px",
          fontSize: "12px",
          lineHeight: "20px",
          alignItems: "center",
        };

      case "Rejected":
        return {
          background: "#FF484229",
          color: "#FF4842",
          borderRadius: "6px",
          fontWeight: "700",
          // maxWidth: "78px",
          // maxHeight: "22px",
          textAlign: "center",
          padding: "1px, 8px, 1px, 8px",
          fontSize: "12px",
          lineHeight: "20px",
          alignItems: "center",
        };

      default:
        return {
          display: "flex",
          alignItems: "center",
          // justifyContent: "center",
        };
    }
  } else {
    return {
      display: "flex",
      alignItems: "center",
      // justifyContent: "center",
    };
  }
};
