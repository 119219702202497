import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import CommonListTable from "../../../components/CommonListTable";
import CommonPageTitle from "../../../components/CommonPageTitle";
import Pagination from "../../../components/CommonPagination";
import SearchFilter from "../../../components/SearchFilter";
import CommonConfirmationModal from "../../../components/CommonConfirmationModal";
import { VESSEL_OWNER, listEmptyMessage } from "../../../utils/constants";
import { getRole } from "../../../utils/utility";
import {
  useDeleteCarvingMutation,
  useLazyFetchCarvingQuery,
  useLazyFetchPendingCarvingQuery,
} from "../../../services/modules/carving";
import Loader from "../../../components/Loader";
import { useLazyGetRegistrationCertificateQuery } from "../../../services/modules/common/file";
import CommonModal from "../../../components/CommonModal";

function CarvingList({ ...props }) {
  const navigate = useNavigate();
  const role = getRole();

  const [modalData, setModalData] = useState({
    show: false,
    title: "",
    isSuccess: false,
    description: "",

    
  });
  const [confirmModalData, setConfirmModalData] = useState({
    show: false,
    title: "Delete Vessel",
    description: "",
    cancelLabel: "Cancel",
    confirmLabel: "Delete",
    handleConfirm: () => {},
  });
  const [getRegistrationCertificate, { data: certpdf, isSuccess: successpdf ,isFetching}] =
    useLazyGetRegistrationCertificateQuery();

  const handleClickView = (data) => {
    const { vesselId, _id: carvingId } = data;
    navigate("/carving-request-details", {
      state: {
        vesselId: vesselId,
        carvingId: role === VESSEL_OWNER ? null : carvingId,
        mode: "view",
      },
    });
  };

  const handleClickNew = () => {
    navigate("/carving-request-details");
  };

  const handleClickEdit = (data) => {
    const { vesselId } = data;
    if (role === VESSEL_OWNER) {
      navigate("/carving-request-details", {
        state: {
          vesselId,
          mode: "edit",
        },
      });
    }
  };

  const handleHistoryClick = (data) => {
    const { vesselId } = data;

    navigate("./vessel-history", {
      state: {
        vesselId,
      },
    });
  };

  const handleClickDelete = (data) => {
    console.log(data);
    setConfirmModalData((prev) => {
      return {
        ...prev,
        show: true,
        description: `Do you want to delete the vessel ${data.vesselName}`,
        handleConfirm: () => {
          handleDelete(data);
          setConfirmModalData((prev) => {
            return {
              ...prev,
              show: false,
            };
          });
        },
      };
    });
  };
  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  useEffect(() => {
    if (!successpdf) return;

    const blob = dataURItoBlob(certpdf);
    const url = URL.createObjectURL(blob);

    window.open(url, "_blank");
  }, [successpdf, certpdf]);
  const handleRegistrationSurvey = (data) => {
    const { vesselId } = data;
    getRegistrationCertificate({ vesselId });
  };

  const rowHead = [
    { id: 1, heading: "Sl.No", field: "slno" },
    {
      id: 2,
      heading: "Reference Number",
      field: "referenceNo",
      filterBy: true,
    },
    { id: 3, heading: "Vessel Name", field: "vesselName", filterBy: true },
    {
      id: 4,
      heading: "Date of Submission",
      field: "submittedOn",
      filterBy: true,
    },
    {
      id: 5,
      heading: "Status",
      field: "carvingStatus",
      filterBy: true,
    },
    { id: 6, heading: "View", onClick: handleClickView },
    {
      id: 7,
      heading: "More",
      options: {
        "Carving Drafted": [
          { label: "Edit", onClick: handleClickEdit },
          { label: "Delete", onClick: handleClickDelete },
          { label: "History", onClick: handleHistoryClick },
        ],
        Draft: [
          { label: "Edit", onClick: handleClickEdit },
          { label: "Delete", onClick: handleClickDelete },
          { label: "History", onClick: handleHistoryClick },
        ],
        Rejected: [
          { label: "Edit", onClick: handleClickEdit },
          { label: "Delete", onClick: handleClickDelete },
          { label: "History", onClick: handleHistoryClick },
        ],
        "Carving Approved": [
          {
            label: "Download Registration Certificate",
            onClick: handleRegistrationSurvey,
          },
          { label: "History", onClick: handleHistoryClick },
        ],
        Approved: [
          {
            label: "Download Registration Certificate",
            onClick: handleRegistrationSurvey,
          },
          { label: "History", onClick: handleHistoryClick },
        ],
        "Carving Submitted": [
          { label: "History", onClick: handleHistoryClick },
        ],
        "Under Processing": [{ label: "History", onClick: handleHistoryClick }],
      },
    },
  ];

  const searchFilters = {
    referenceNo: {
      value: "referenceNo",
      label: "Reference Number",
      type: "search",
    },
    vesselName: {
      value: "vesselName",
      label: "Vessel Name",
      type: "search",
    },
    submittedOn: {
      value: "submittedOn",
      label: "Date of Submission",
      type: "date",
    },
    carvingStatus: {
      value: "carvingStatus",
      label: "Status",
      type: "select",
      typeMetadata: {
        options: {
          All: "",
          Draft: "Draft",
          Submitted: "Submitted",
          "Under Processing": "Under Processing",
          Approved: "Approved",
          Rejected: "Rejected",
        },
      },
    },
  };

  const title = role === VESSEL_OWNER ? "Registration" : "Registration Requests";

  const [page, setPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState("referenceNo");
  const [searchString, setSearchString] = useState("");
  const handlePageChange = (currentPage) => setPage(currentPage?.selected + 1);
  const handleFilterChange = (e) => {
    setSearchFilter(e.target.value);
    setSearchString("");
  };

  const handleSearch = (query) => {
    setSearchString(query.trim());
    setPage(1);
  };

  const [fetchCarving, { data: carvingListData, isLoading }] =
    useLazyFetchCarvingQuery();

  const [
    fetchPendingCarving,
    { data: pendingCarvingListData, isLoading: isPendingCarvingLoading },
  ] = useLazyFetchPendingCarvingQuery();

  const [deleteCarving, { data: deleteCarvingData }] =
    useDeleteCarvingMutation();

  useEffect(() => {
    const searchParams = `${searchFilter}=${searchString}`;
    console.log(searchParams);
    if (role === VESSEL_OWNER)
      fetchCarving({
        searchParams: searchParams,
        page,
      });
    else
      fetchPendingCarving({
        searchParams: searchParams,
        page,
      });
  }, [
    page,
    searchString,
    searchFilter,
    fetchCarving,
    fetchPendingCarving,
    role,
    deleteCarvingData,
  ]);

  const handleDelete = async (data) => {
    const carvingId = data?._id;
    const deletedCarving = await deleteCarving({ id: carvingId });

    if (deletedCarving.data?.statusCode === 200) {
      setModalData({
        show: true,
        title: "Success",
        isSuccess: true,
        description: "Carving Deleted",
      });
    } else {
      // Error deleting vessel details
      setModalData({
        show: true,
        title: "Error",
        isSuccess: false,
        description: deletedCarving?.error?.data?.message,
      });
    }
  };

  const searchPlaceholder = searchFilters?.[searchFilter]?.label;

  if (isLoading || isPendingCarvingLoading ||isFetching) return <Loader />;
  return (
    <Container>
      <CommonConfirmationModal
        handleClose={() => {
          setConfirmModalData((prev) => {
            return { ...prev, show: false };
          });
        }}
        {...confirmModalData}
      />
      <CommonModal
        buttonText={"OK"}
        handleClose={() => {
          setModalData((prev) => {
            return { ...prev, show: false };
          });
        }}
        {...modalData}
      />
      <Breadcrumb crumbs={props.crumbs} />
      <CommonPageTitle title={title} arrowback={false} />
      <div className="card">
        <div className="container card-container">
          <div className="row">
            <SearchFilter
              searchFilters={searchFilters}
              onFilterChange={handleFilterChange}
              onValueChange={handleSearch}
              searchFilter={searchFilter}
              placeholder={`Search ${searchPlaceholder}`}
            />
            {role === VESSEL_OWNER ? (
              <div className="col-md-6 d-flex justify-content-end">
                <div className="text-box">
                  <Button onClick={handleClickNew} className="add-new-btn">
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="table-row">
            <CommonListTable
              data={
                role === VESSEL_OWNER
                  ? carvingListData?.result?.data ?? []
                  : pendingCarvingListData?.result?.data ?? []
              }
              rowHead={rowHead}
              page={page}
            />

            {(role === VESSEL_OWNER &&
              carvingListData?.result?.data?.length < 1) ||
            pendingCarvingListData?.result?.data?.length < 1 ? (
              <div className="no-data">{listEmptyMessage}</div>
            ) : (
              <Pagination
                pageCount={
                  role === VESSEL_OWNER
                    ? carvingListData?.result?.totalElements ?? 0
                    : pendingCarvingListData?.result?.totalElements ?? 0
                }
                handlePageChange={handlePageChange}
                forcePage={page - 1}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
export default CarvingList;
