import { pagination } from "../../../utils/constants";

const API_ENDPOINT = "/carvings";

export const addCarving = (build) => {
  return build.mutation({
    query: ({ data }) => ({
      url: `${API_ENDPOINT}`,
      method: "POST",
      body: data,
    }),
  });
};

export const fetchCarving = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `${API_ENDPOINT}/vo/list?${params.join("&")}`;
      return url;
    },
  });
};

export const fetchCarvingById = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `${API_ENDPOINT}/${id}`;
      return url;
    },
  });
};

export const updateCarving = (build) => {
  return build.mutation({
    query: ({ id, data }) => ({
      url: `${API_ENDPOINT}/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
};

export const deleteCarving = (build) => {
  return build.mutation({
    query: ({ id }) => ({
      url: `${API_ENDPOINT}/${id}`,
      method: "DELETE",
    }),
  });
};

export const fetchPendingCarving = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `${API_ENDPOINT}/pending/list?${params.join("&")}`;
      return url;
    },
  });
};

export const submitCarvingProcess = (build) => {
  return build.mutation({
    query: ({ id }) => ({
      url: `${API_ENDPOINT}/submit/${id}`,
      method: "POST",
    }),
  });
};

export const approveCarvingProcess = (build) => {
  return build.mutation({
    query: ({ id, data }) => ({
      url: `${API_ENDPOINT}/approve/${id}`,
      method: "POST",
      body: data,
    }),
  });
};

export const rejectCarvingProcess = (build) => {
  return build.mutation({
    query: ({ id, data }) => ({
      url: `${API_ENDPOINT}/reject/${id}`,
      method: "POST",
      body: data,
    }),
  });
};
