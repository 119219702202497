const API_ENDPOINT = "/payment";

export const addOrder = (build) => {
  return build.mutation({
    query: ({ data }) => ({
      url: `${API_ENDPOINT}/order`,
      method: "POST",
      body: data,
    }),
  });
};
