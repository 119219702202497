import { useState } from "react";
import loadScript from "../utils/script-loader";

const useRazorPay = () => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);  
  const [paymentError, setPaymentError] = useState(false);
  const RAZORPAY_CHECKOUT_SCRIPT_URL =
    "https://checkout.razorpay.com/v1/checkout.js";
  const handlePayment = async (options) => {
    const loadedScript = document.querySelector(
      `script[src="${RAZORPAY_CHECKOUT_SCRIPT_URL}"]`
    );
    if (!loadedScript) await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);

    const razorPay = new window.Razorpay(options);
    // Payment succeeded
    razorPay.on("payment.submit", (_) => {
      setPaymentSuccess(true);
      setPaymentError(null);
    });

    // Payment failed
    razorPay.on("payment.failed", (response) => {
      setPaymentSuccess(false);
      setPaymentError(response.error);
    });

    // Open razorpay checkout modal
    razorPay.open();
  };

  return { handlePayment, paymentSuccess, paymentError };
};

export { useRazorPay };
