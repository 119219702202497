import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../config";
import UserService from "./UserService";
import { showError, setResponse, hideError } from "./LoadingSlice";
//import { store } from "../store";
// Define a service using a base URL and expected endpoints

//Define base query configurations

const baseQuery = fetchBaseQuery({
  baseUrl: Config.MASTER_SERVICE_API,
  prepareHeaders: (headers) => {
    const token = UserService.getToken();
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    localStorage.clear();
    UserService.doLogout();
  } else {
    // store.dispatch(setResponse(result));
  }
  return result;
};

const MasterServiceAPI = createApi({
  baseQuery: baseQueryWithInterceptor,
  reducerPath: "MasterServiceApi",
  tagTypes: [
    "User",
    "District",
    "Roles",
    "Port",
    "Survey",
    "VesselTypes",
    "VesselSubTypes",
    "VesselCategories",
    "PreApprovalTypes",
    "PortOfRegistry",
    "Rates",
    "OffenceCategory",
    "OffenceSubCategory",
    "OffenceRule",
    "OffenceNature",
    "Taxes",
    "SurveyApplication",
  ],
  endpoints: () => ({}),
});

export default MasterServiceAPI;
