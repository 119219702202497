import MasterServiceAPI from "../../MasterServiceAPI";
import { getOtp, verifyOtp } from "./otp";

export const OtpAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    getOtp: getOtp(build),
    verifyOtp: verifyOtp(build),
  }),
  overrideExisting: false,
});

export const { useLazyGetOtpQuery, useVerifyOtpMutation } = OtpAPI;
