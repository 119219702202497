import MasterServiceAPI from "../../MasterServiceAPI";
import { addOrder } from "./orderPayment";

export const PaymentAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addOrder: addOrder(build),
  }),
  overrideExisting: false,
});

export const { useAddOrderMutation } = PaymentAPI;
