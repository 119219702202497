import { pagination } from "../../../../utils/constants";

export const fetchRole = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/roles?${params.join("&")}`;
      return url;
    },
    providesTags: ["Role"],
  });
};
export const fetchRolebyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/roles/${id}`;
      return url;
    },
  });
};
export const fetchRolebyRoleCode = (build) => {
  return build.query({
    query: ({ roleCode }) => {
      const url = `/roles/byCode/${roleCode}`;
      return url;
    },
  });
};

export const updateRole = (builder) =>
  builder.mutation({
    query: ({ data, id }) => ({
      url: `/roles/${id}`,
      method: "PATCH",
      body: data,
    }),
    invalidatesTags: ["Role"],
  });

export const addRole = (builder) =>
  builder.mutation({
    query: (data) => ({
      url: `/roles`,
      method: "post",
      body: data,
    }),
  });
export const deleteRole = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `roles/${id}`,
      method: "DELETE",
    }),
  });
