import { pagination } from "../../../../utils/constants";

const API_ENDPOINT = "/rates";

export const addRate = (build) => {
  return build.mutation({
    query: (data) => ({
      url: `${API_ENDPOINT}`,
      method: "POST",
      body: data,
    }),
  });
};

export const fetchRate = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `${API_ENDPOINT}?${params.join("&")}`;
      return url;
    },
    providesTags: ["Rates"],
  });
};

export const fetchRateById = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `${API_ENDPOINT}/${id}`;
      return url;
    },
  });
};

export const fetchRateByCategory = (build) => {
  return build.query({
    query: ({ category }) => {
      const url = `${API_ENDPOINT}/byCategory/${category}`;
      return url;
    },
  });
};
export const fetchRateByApplicationId = (build) => {
  return build.query({
    query: ({ category, applicationId }) => {
      const url = `${API_ENDPOINT}/byApplication?category=${category}&applicationId=${applicationId}`;
      return url;
    },
  });
};

export const updateRate = (build) => {
  return build.mutation({
    query: ({ data, id }) => ({
      url: `${API_ENDPOINT}/${id}`,
      method: "PATCH",
      body: data,
    }),
    invalidatesTags: ["Rates"],
  });
};

export const deleteRate = (build) => {
  return build.mutation({
    query: ({ id }) => ({
      url: `${API_ENDPOINT}/${id}`,
      method: "DELETE",
    }),
  });
};

export const fetchCategory = (build) => {
  return build.query({
    query: () => {
      const url = `${API_ENDPOINT}/category`;
      return url;
    },
  });
};
