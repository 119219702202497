import { pagination } from "../../../../utils/constants";

export const fetchDistricts = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/districts?${params.join("&")}`;
      return url;
    },
    providesTags: ["District"],
  });
};
export const fetchDistrictById = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/districts/${id}`;
      return url;
    },
  });
};
export const updateDistrict = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `districts/${id}`,
      method: "PATCH",
      body: data,
    }),
    invalidatesTags: ["District"],
  });
export const addDistrict = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/districts`,
      method: "post",
      body: data,
    }),
  });
export const deleteDistrict = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `districts/${id}`,
      method: "DELETE",
    }),
  });
