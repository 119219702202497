import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import CommonConfirmationModal from "../../../components/CommonConfirmationModal";
import CommonListTable from "../../../components/CommonListTable";
import CommonModal from "../../../components/CommonModal";
import CommonPageTitle from "../../../components/CommonPageTitle";
import Pagination from "../../../components/CommonPagination";
import SearchFilter from "../../../components/SearchFilter";
import Loader from "../../../components/Loader";
import {
  useLazyFetchPreApprovalListQuery,
  useLazyFetchPreApprovalPendingListQuery,
} from "../../../services/modules/PreApprovals";
import {
  useDeleteVesselAdditionalDetailsMutation,
  useDeleteVesselMutation,
  useLazyFetchVesselbyIdQuery,
} from "../../../services/modules/vesselowner/preapproval";
import {
  VESSEL_OWNER,
  listEmptyMessage,
  pagination,
} from "../../../utils/constants";
import { getLocalStorageItem, getRole } from "../../../utils/utility";
import {
  useLazyGeneratePdfQuery,
  useLazyGetPreApprovalPreviewApplicationQuery,
  useLazyGetPreApprovalPreviewQuery,
} from "../../../services/modules/common/file";
import { processType } from "../../../utils/constants";

function Index({ ...props }) {
  const navigate = useNavigate();

  const [modalData, setModalData] = useState({
    show: false,
    title: "",
    isSuccess: false,
    description: "",
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilter, setSearchFilter] = useState("referenceNo");
  const role = getRole();
  const [userInfo, setUserInfo] = useState(null);

  const [fetchPreApprovalList, { data: preApprovalData, isLoading }] =
    useLazyFetchPreApprovalListQuery();

  const [fetchVesselbyId] = useLazyFetchVesselbyIdQuery();

  const [deleteVessel, { data: vesselDeleted, isSuccess: isDeleteSuccess }] =
    useDeleteVesselMutation();

  const [deleteVesselAdditionalDetails] =
    useDeleteVesselAdditionalDetailsMutation();

  const [
    fetchPendingPreApprovalList,
    { data: preApprovalPendingData, isLoadingPendingList },
  ] = useLazyFetchPreApprovalPendingListQuery();

  // const [
  //   getPreApprovalPreview,
  //   {
  //     data: certpdf,
  //     isSuccess: successpdf,
  //     isFetching: isFetching,
  //     isLoading: iscertpdfLoading,
  //   },
  // ] = useLazyGetPreApprovalPreviewQuery();

  const [
    getPreApprovalPreviewApplication,
    {
      data: certpdf,
      isSuccess: successpdf,
      isFetching: isFetching,
      isLoading: iscertpdfLoading,
    },
  ] = useLazyGetPreApprovalPreviewApplicationQuery();



  useEffect(() => {
    const userDetail = getLocalStorageItem("userInfo");
    if (userDetail) {
      setUserInfo(userDetail);
    }
  }, []);

  useEffect(() => {
    if (isDeleteSuccess && page >= 1) {
      const totalElements =
        role === VESSEL_OWNER
          ? preApprovalData?.result?.totalElements ?? 0
          : preApprovalPendingData?.result?.totalElements ?? 0;
      const totalPages = Math.ceil(totalElements / pagination.limit);
      if (totalPages < page) {
        setPage(totalPages);
      }
    }
  }, [
    isDeleteSuccess,
    page,
    role,
    preApprovalData?.result?.totalElements,
    preApprovalPendingData?.result?.totalElements,
  ]);

  useEffect(() => {
    if (!successpdf) return;
    const blob = dataURItoBlob(certpdf);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }, [successpdf, certpdf]);

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  useEffect(() => {
    const searchParams = `${searchFilter}=${searchQuery}`;
    if (role === VESSEL_OWNER) {
      fetchPreApprovalList({
        searchParams: searchParams,
        page: page,
        // preApprovalCode: props.requestTypeCode,
        // type: processType.PRE_APPROVAL,
      });
    } else {
      fetchPendingPreApprovalList({
        searchParams: searchParams,
        page: page,
      });
    }
  }, [
    page,
    fetchPreApprovalList,
    fetchPendingPreApprovalList,
    vesselDeleted,
    role,
    searchFilter,
    searchQuery,
    userInfo,
    props.requestTypeCode,
  ]);
  const title =
    role === VESSEL_OWNER ? "New Vessel Requests" : "Vessel Request";
  const viewClick = (data) => {
    const { processStatus, vesselId } = data;

    if (role === VESSEL_OWNER) {
      navigate("/new-request-add-vessel", {
        state: {
          vesselIdTobeEdited: vesselId,
          // edit: processStatus === "Rejected" || processStatus === "Draft",
          requestTypeCode: props.requestTypeCode,
        },
      });
    } else {
      navigate("/vessel-request-details", {
        state: {
          Id: data._id,
          vesselStatus: data.vesselStatus,
          vesselId: vesselId,
        },
      });
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query.trim());
    setPage(1);
  };

  const handlePageChange = (currentPage) => {
    setPage(currentPage?.selected + 1);
  };
  const handleFilterChange = (e) => {
    setSearchFilter(e.target.value);
    setSearchQuery("");
  };

  const editClick = (data) => {
    const { processStatus, vesselId } = data;
    if (role === VESSEL_OWNER) {
      navigate("/new-request-add-vessel", {
        state: {
          vesselIdTobeEdited: vesselId,
          edit:
            processStatus === "Draft" || processStatus === "Rejected"
              ? true
              : false,
          requestTypeCode: props.requestTypeCode,
        },
      });
    }
  };

  const [confirmModalData, setConfirmModalData] = useState({
    show: false,
    title: "Delete Vessel",
    description: "",
    cancelLabel: "Cancel",
    confirmLabel: "Delete",
    handleConfirm: () => {},
  });

  const deleteClick = (data) => {
    console.log(data);
    setConfirmModalData((prev) => {
      return {
        ...prev,
        show: true,
        description: `Do you want to delete the vessel ${data.vesselName}`,
        handleConfirm: () => {
          handleDelete(data);
          setConfirmModalData((prev) => {
            return {
              ...prev,

              show: false,
            };
          });
        },
      };
    });
  };
  const historyClick = (data) => {
    const { vesselId } = data;
    navigate("./vessel-history", {
      state: {
        vesselId,
      },
    });
  };

  const handlePreviewDownload = (data) => {
    const vesselId = data?.vesselId;
    getPreApprovalPreviewApplication({ vesselId });
  };

  const handleDelete = async (data) => {
    setIsDeleting(true);
    const { vesselId } = data;
    // Obtain vessel additional details id
    const vesselToBeDeleted = await fetchVesselbyId({ id: vesselId });

    // Id of additional details
    const vesselAdditionalDetailsId =
      vesselToBeDeleted.data.result[0].vesselAdditionalDetail?._id;

    // Delete Additional details
    const deletedVesselAdditionalDetails = await deleteVesselAdditionalDetails({
      id: vesselAdditionalDetailsId,
    });

    if (deletedVesselAdditionalDetails.data?.statusCode === 200) {
      // Delete vessel
      const deletedVessel = await deleteVessel({ id: vesselId });

      if (deletedVessel.data?.statusCode === 200) {
        setIsDeleting(false);
        setModalData({
          show: true,
          title: "Success",
          isSuccess: true,
          description: "Vessel Deleted",
        });
      } else {
        // Error deleting vessel details
        setIsDeleting(false);
        setModalData({
          show: true,
          title: "Error",
          isSuccess: false,
          description: deleteVessel?.error?.data?.message,
        });
      }
    } else {
      setIsDeleting(false);
      // Error deleting vessel additional details
      setModalData({
        show: true,
        title: "Error",
        isSuccess: false,
        description: deletedVesselAdditionalDetails?.error?.data?.message,
      });
    }
  };

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  const [
    generatePdf,
    {
      data: generatedPdf,
      isSuccess: isPdfGenerated,
      isFetching: isFetchingPdf,
    },
  ] = useLazyGeneratePdfQuery();
  const downloadConsent = (data) => {
    const { vesselId } = data;
    generatePdf({ vesselId });
  };
  useEffect(() => {
    if (!isPdfGenerated) return;
    const blob = dataURItoBlob(generatedPdf);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }, [isPdfGenerated, generatedPdf]);

  const rowHead = [
    { id: 1, heading: "SlNo", field: "slno" },
    { id: 2, heading: "Reference Number", field: "referenceNo" },
    { id: 3, heading: "Vessel Name", field: "vesselName" },
    { id: 4, heading: "Date of Submission", field: "submittedOn" },
    { id: 5, heading: "Status", field: "processStatus" },
    { id: 6, heading: "View", onClick: viewClick },
    {
      id: 7,
      heading: "More",
      options: {
        Draft: [
          { label: "Edit", onClick: editClick },
          { label: "Delete", onClick: deleteClick },
          { label: "History", onClick: historyClick },
        ],
        Submitted: [
          { label: "History", onClick: historyClick },
          {
            label: "Download Application",
            onClick: handlePreviewDownload,
          },
        ],
        Rejected: [
          { label: "Edit", onClick: editClick },
          { label: "Delete", onClick: deleteClick },
          { label: "History", onClick: historyClick },
          {
            label: "Download Application",
            onClick: handlePreviewDownload,
          },
        ],
        "Under Processing": [
          { label: "History", onClick: historyClick },
          {
            label: "Download Application",
            onClick: handlePreviewDownload,
          },
        ],
        Approved: [
          {
            label: "Download Consent",
            onClick: downloadConsent,
          },
          { label: "History", onClick: historyClick },
          {
            label: "Download Application",
            onClick: handlePreviewDownload,
          },
        ],
        Pending:
          role === VESSEL_OWNER ? [] : [{ label: "Edit", onClick: editClick }],
      },
    },
  ];
  const searchFilters = {
    referenceNo: {
      value: "referenceNo",
      label: "Reference Number",
      type: "search",
    },
    vesselName: {
      value: "vesselName",
      label: "Vessel Name",
      type: "search",
    },
    submittedOn: {
      value: "submittedOn",
      label: "Date of Submission",
      type: "date",
    },
    processStatus: {
      value: "processStatus",
      label: "Status",
      type: "select",
      typeMetadata: {
        options: {
          All: "",
          Draft: "Draft",
          Submitted: "Submitted",
          "Under Processing": "Under Processing",
          Approved: "Approved",
          Rejected: "Rejected",
        },
      },
    },
  };

  const searchPlaceholder = searchFilters?.[searchFilter]?.label;

  const handleClickNew = () => {
    navigate("/new-request-add-vessel", {
      state: {
        vesselIdTobeEdited: "",
        edit: true,
        commonStepper: true,
        requestTypeCode: props.requestTypeCode,
      },
    });
  };

  if (isLoading || isLoadingPendingList || isDeleting || isFetchingPdf || isFetching)
    return <Loader />;
  return (
    <Container>
      <CommonConfirmationModal
        handleClose={() => {
          setConfirmModalData((prev) => {
            return { ...prev, show: false };
          });
        }}
        {...confirmModalData}
      />
      <CommonModal
        buttonText={"OK"}
        handleClose={() => {
          setModalData((prev) => {
            return { ...prev, show: false };
          });
        }}
        {...modalData}
      />
      <Breadcrumb crumbs={props.crumbs} />
      <CommonPageTitle title={title} arrowback={false} />
      <div className="card">
        <div className="container card-container">
          <div className="row">
            <SearchFilter
              searchFilters={searchFilters}
              onFilterChange={handleFilterChange}
              onValueChange={handleSearch}
              searchFilter={searchFilter}
              placeholder={`Search ${searchPlaceholder}`}
            />
            {role === VESSEL_OWNER ? (
              <div className="col-md-6 d-flex justify-content-end">
                <div className="text-box">
                  <Button
                    label={"Add Vessel"}
                    className="add-new-btn"
                    onClick={handleClickNew}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="table-row">
            <CommonListTable
              data={
                role === VESSEL_OWNER
                  ? preApprovalData?.result?.data ?? []
                  : preApprovalPendingData?.result?.data ?? []
              }
              rowHead={rowHead}
              page={page}
            />
            {!isLoading &&
              role === VESSEL_OWNER &&
              preApprovalData &&
              preApprovalData?.result?.totalElements === 0 && (
                <div className="no-data">{listEmptyMessage}</div>
              )}
            {!isLoading &&
              role !== VESSEL_OWNER &&
              preApprovalPendingData &&
              preApprovalPendingData?.result?.data?.length === 0 && (
                <div className="no-data"> {listEmptyMessage}</div>
              )}
            <Pagination
              pageCount={
                role === VESSEL_OWNER
                  ? preApprovalData?.result?.totalElements ?? 0
                  : preApprovalPendingData?.result?.totalElements ?? 0
              }
              handlePageChange={handlePageChange}
              forcePage={page - 1}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Index;
