import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  addRole,
  fetchRole,
  fetchRolebyId,
  updateRole,
  deleteRole,
  fetchRolebyRoleCode,
} from "./Role";

export const RoleDetailsAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addRole: addRole(build),
    fetchRole: fetchRole(build),
    fetchRolebyId: fetchRolebyId(build),
    updateRole: updateRole(build),
    deleteRole: deleteRole(build),
    fetchRolebyRoleCode: fetchRolebyRoleCode(build),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchRoleQuery,
  useLazyFetchRolebyIdQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useLazyFetchRolebyRoleCodeQuery,
} = RoleDetailsAPI;
