import { pagination } from "../../../utils/constants";

export const submitStageVerificationbyNA = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `stage-verification/submit/${id}`,
      method: "POST",
      body: data,
    }),
  });
export const requestForStageVerificationbyVO = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `stage-verification/request/${id}`,
      method: "POST",
      body: data,
    }),
  });
export const informStageVerificationbyNA = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `stage-verification/inform/${id}`,
      method: "POST",
      body: data,
    }),
  });

export const approveStageVerificationbyNA = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `stage-verification/approve/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const rejectStageVerificationbyNA = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `stage-verification/reject/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const fetchStageVerificationPendingList = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/stage-verification/pending/list?${params.join("&")}`;
      return url;
    },
  });
};
export const fetchStageVerificationbyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/stage-verification/${id}`;
      return url;
    },
  });
};

export const updateStageVerificationRequest = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `stage-verification/request/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const updateStageVerificationInform = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `stage-verification/inform/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const updateStageVerificationApprove = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `stage-verification/approve/${id}`,
      method: "PATCH",
      body: data,
    }),
  });

  export const fetchStabilityDocumentsByVesselId = (build) => {
    return build.query({
      query: ({ searchParams }) => {
        const params = [];
        if (searchParams) params.push(searchParams);
        const url = `/stage-verification/byVesselId?${params.join("&")}`;
        return url;
      },
    });
  };

  
