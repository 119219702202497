import { mixed, object, string } from "yup";
import { ownershipTypes } from "../../../utils/constants";

export const validationSchema = object().shape({
  houseName: string()
    .required("House name is required")
    .matches(
      /^[a-zA-Z0-9.,'()@!&-_\s]+$/, 
      "Only letters, numbers, and the following special characters are allowed: .,'()@!&-_"
    ),
  districtId: string().required("District is required"),
  city: string()
    .required("City is required")
    .matches(
      /^[a-zA-Z0-9., ]+$/,
      "Only letters, numbers and spaces are allowed"
    ),
  postOffice: string()
    .required("Post Office is required")
    .matches(
      /^[a-zA-Z0-9., ]+$/,
      "Only letters, numbers and spaces are allowed"
    ),
  aadharNo: string()
    .matches(/^\d{12}$/, "Aadhar Number is not valid")
    .required("Aadhar Number is required!"),
  pinCode: string()
    .required("Pincode is required")
    .matches(/^[1-9][0-9]{5}$/, "Enter a valid pincode"),
  otp: string().required("OTP is Required").min(6, "OTP must be 6 digits"),
  typeOfOwnership: string().required("Ownership type is required"),
  temporaryAddress: string(),
  mobile: string()
    .required("Phone number is required")
    .matches(/^[6-9]\d{9}$/, "Phone Number is not valid"),
  aadharFile: mixed().required("Aadhar file is required!"),
  profilePic: mixed().when("typeOfOwnership", {
    is: (typeOfOwnership) => typeOfOwnership === ownershipTypes.individual,
    then: () =>
      mixed()
        .required("Owner photo is Required")
        .test("fileFormat", "Only image files are allowed", (file) => {
          if (file) {
            return file.type.match("image/*");
          }
          return true;
        }),
  }),
});
