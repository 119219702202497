import Pagination from "../../../../components/CommonPagination";
import { pagination } from "../../../../utils/constants";

export const fetchYard = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);

      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/yard?${params.join("&")}`;
      return url;
    },
  });
};
export const fetchValidYard = (build) => {
  return build.query({
    query: ({ type }) => {
      const url = `/yard/valid`;
      return url;
    },
  });
};

export const updateYard = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `yard/${id}`,
      method: "PATCH",
      body: data,
    }),
  });

export const addYard = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/yard`,
      method: "post",
      body: data,
    }),
  });

// export const fetchYardbyId = (build) => {
//   return build.query({
//     query: ({ id }) => {
//       const url = `/yard/${id}`;
//       return url;
//     },
//   });
// };

export const fetchYardbyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/yard/${id}`;
      return url;
    },
  });
};

export const addYardFromAdmin = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/yard/fromAdmin`,
      method: "post",
      body: data,
    }),
  });
