const API_ENDPOINT = "/files";

export const uploadFile = (build) => {
  return build.mutation({
    query: ({ data }) => ({
      url: `${API_ENDPOINT}/upload/`,
      method: "POST",
      body: data,
    }),
  });
};

export const fetchFiles = (build) => {
  return build.query({
    query: () => {
      const url = `${API_ENDPOINT}`;
      return url;
    },
  });
};

export const fetchFileById = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `${API_ENDPOINT}/${id}`;
      return url;
    },
  });
};

export const deleteFile = (build) => {
  return build.mutation({
    query: ({ id }) => ({
      url: `${API_ENDPOINT}/${id}`,
      method: "DELETE",
    }),
  });
};

export const downloadFile = (build) => {
  return build.query({
    query: ({ fileName }) => ({
      url: `${API_ENDPOINT}/download/${fileName}`,
      responseHandler: async (response) => {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        return url;
      },
    }),
  });
};
