import { Accordion, Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import "../pages/Survey/Request/style.scss";
import { getRole } from "../utils/utility";
import { Config } from "../config";
import {
  NAVAL_ARCHITECTURE,
  PORT_CONSERVATOR,
  fileCategories,
} from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { useLazyDownloadFileQuery } from "../services/modules/common/file";

const CommonFileAccordian = ({ vesselDataAdditional }) => {
  const role = getRole();
  const [downloadFile, { data }] = useLazyDownloadFileQuery();

  const uploads = vesselDataAdditional?.uploads;

  const uploadsArray = [];
  for (const key in uploads) {
    if (key.value !== "null") {
      if (Object.hasOwnProperty.call(uploads, key)) {
        uploadsArray.push({ key: key, value: uploads[key] });
      }
    }
  }

  const navigate = useNavigate();

  const handleClick = (fileName) => {
    if (role === PORT_CONSERVATOR || role === NAVAL_ARCHITECTURE) {
      navigate(`./owner-documents?fileName=${fileName}`);
    } else {
      downloadFile({ fileName })
        .then((response) => {
          window.open(response.data, "_blank");
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  };

  function getFileName(fileName) {
    return fileCategories[fileName]?.label || fileName;
  }

  return (
    <Container className="dashboard-container">
      <div className="d-flex flex-column gap-4 card">
        <Accordion alwaysOpen flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header> Vessel Documents</Accordion.Header>
            <Accordion.Body>
              <Row className="justify-content-between mt-4 m-4 gap-5">
                {uploadsArray.map((item) => {
                  const { key, value } = item;
                  console.log(key);

                  return (
                    value !== "" && (
                      <Col
                        md={5}
                        className="p-4 d-flex justify-content-between align-items-center"
                        style={{ border: "1px solid #CBC9c9" }}
                      >
                        {" "}
                        <Col>{getFileName(key)}</Col>
                        <Col
                          onClick={() => handleClick(value)}
                          className="d-flex justify-content-end align-items-center gap-2"
                          role="button"
                        >
                          <FontAwesomeIcon
                            style={{
                              color: "#3D5CB8",
                            }}
                            icon={faEye}
                          />
                          <div>View</div>
                        </Col>
                      </Col>
                    )
                  );
                })}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </Container>
  );
};

export default CommonFileAccordian;
