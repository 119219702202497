import { pagination } from "../../../../utils/constants";
export const fetchPreApprovalTypes = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/pre-approval-type?${params.join("&")}`;
      return url;
    },
    providesTags: ["PreApprovalTypes"],
  });
};
export const fetchPreApprovalTypebyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/pre-approval-type/${id}`;
      return url;
    },
  });
};

export const addPreApprovalType = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/pre-approval-type`,
      method: "post",
      body: data,
    }),
  });
export const updatePreApprovalType = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `pre-approval-type/${id}`,
      method: "PATCH",
      body: data,
    }),
    invalidatesTags: ["PreApprovalTypes"],
  });
export const deletePreApprovalType = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `pre-approval-type/${id}`,
      method: "DELETE",
    }),
  });
