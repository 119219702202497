import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useLazyDownloadFileQuery } from "../../services/modules/common/file";
import { AiOutlineDownload } from "react-icons/ai";
import { useLazyFetchStabilityDocumentsByVesselIdQuery } from "../../services/modules/StageVerification";

const StabilityDocuments = ({ vesselId }) => {

  const [stabilityDocuments, setStabilityDocuments] = useState([]);
  const [fetchStabilityDocumentsByVesselId, { data: stabilityDocumentData }] =
    useLazyFetchStabilityDocumentsByVesselIdQuery();

  //Fetch stability documnets by vessel id
  useEffect(() => {
    const type = "Stability";
    fetchStabilityDocumentsByVesselId({
      searchParams: `vesselId=${vesselId}&type=${type}`,
    });
  }, [vesselId, fetchStabilityDocumentsByVesselId]);

  useEffect(() => {
    if (stabilityDocumentData)
      setStabilityDocuments(stabilityDocumentData?.result);
  }, [stabilityDocumentData]);

  const [downloadFile, { isFetching }] = useLazyDownloadFileQuery();

  const hanldeDownloadClick = (fileName) => {
    downloadFile({ fileName })
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const StabilityDocs = [
    {
      label: "Heel Test",
      value: "documentUpload.[0].fileName",
      accept: "application/pdf",
      field: "heelTest",
    },
    {
      label: "Swamp Test",
      value: "documentUpload.[1].fileName",
      accept: "application/pdf",
      field: "swampTest",
    },
    {
      label: "Inclining Test / Inclination test",
      value: "documentUpload.[2].fileName",
      accept: "application/pdf",
      field: "inclinationTest",
    },
    {
      label: "Calculations report Heel & Inclining Test / Inclination test",
      value: "documentUpload.[3].fileName",
      accept: "application/pdf",
      field: "calculationReport",
    },
    {
      label: "Calculation report with flooding",
      value: "documentUpload.[4].fileName",
      accept: "application/pdf",
      field: "calculationReportWithFlooding",
    },
    {
      label: "Damage stability calculations",
      value: "documentUpload.[5].fileName",
      accept: "application/pdf",
      field: "damageStabilityCalculations",
    },
    {
      label: "Gross Tonnage Calculation",
      value: "documentUpload.[6].fileName",
      accept: "application/pdf",
      field: "grossTonnageCalculation",
    },
    {
      label: "Net tonnage Calculation",
      value: "documentUpload.[7].fileName",
      accept: "application/pdf",
      field: "netTonnageCalculation",
    },
  ];
  function getFieldLabel(field) {
    return StabilityDocs.find((doc) => doc.field === field);
  }
  return (
    <Accordion alwaysOpen flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Stability Documents</Accordion.Header>
        <Accordion.Body>
          <Row>
            {stabilityDocuments?.type === "Stability" ? (
              <Container className="p-0">
                {/* <h2 className="my-3">Stability Documents</h2> */}
                <Row className="d-flex p-3">
                  <Row>
                    {stabilityDocuments &&
                      stabilityDocuments?.photos?.map((photo, index) => (
                        <Col md={12} lg={6} xl={4} className="py-2" key={index}>
                          <div
                            className="card d-flex flex-row m-0 py-3 px-2 justify-content-between px-3"
                            style={{ width: "290px", height: "130px" }}
                          >
                            <span className="d-flex justify-content-center align-items-center">
                              Photo {index + 1}
                            </span>
                            <button
                              className="d-flex align-items-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                              onClick={() => {
                                hanldeDownloadClick(photo);
                              }}
                            >
                              <AiOutlineDownload />
                              Download
                            </button>
                          </div>
                        </Col>
                      ))}
                      
                    {stabilityDocuments &&
                      stabilityDocuments?.documentUpload?.map(
                        (uploadedDocument, index) => (
                          <Col
                            md={12}
                            lg={6}
                            xl={4}
                            className="py-2"
                            key={index}
                          >
                            <div
                              className="card d-flex flex-row m-0 py-3 px-2 justify-content-between px-3"
                              style={{ width: "290px", height: "130px" }}
                            >
                              <span className="d-flex justify-content-center align-items-center">
                                {
                                  getFieldLabel(uploadedDocument?.fileTitle)
                                    ?.label
                                }
                              </span>
                              <button
                                className="d-flex align-items-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                                onClick={() => {
                                  hanldeDownloadClick(
                                    uploadedDocument?.fileName
                                  );
                                }}
                              >
                                <AiOutlineDownload />
                                Download
                              </button>
                            </div>
                          </Col>
                        )
                      )}
                  </Row>
                </Row>
              </Container>
            ) : null}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default StabilityDocuments;
