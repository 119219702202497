import { lazy } from "react";
import {
  faDashboard,
  faCircleCheck,
  faClipboardList,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";
import {
  ACTIVE,
  RA,
  VESSEL_OWNER,
  PORT_CONSERVATOR,
  NAVAL_ARCHITECTURE,
} from "../utils/constants";

// import SurveyRequestList from "../pages/Survey/List/SurveyRequestList";
// import SurveyRequestDetails from "../pages/Survey/Request/SurveyRequestDetails";

const PreApprovalList = lazy(() =>
  import("../pages/PreApproval/List/PreApprovalList")
);
const PreApprovalDetails = lazy(() =>
  import("../pages/PreApproval/Request/PreApprovalRequestDetails")
);
const SurveyRequestList = lazy(() =>
  import("../pages/Survey/List/SurveyRequestList")
);
const SurveyRequestDetails = lazy(() =>
  import("../pages/Survey/Request/SurveyRequestDetails")
);
const RegistrationList = lazy(() =>
  import("../pages/Registration/List/RegistrationList")
);
const RegistrationDetails = lazy(() =>
  import("../pages/Registration/Request/RegistrationDetails")
);
const Dashboard = lazy(() => import("../pages/Dashboard"));
// const role = PORT_CONSERVATOR;

export const SurveyorRoutes = [
  {
    name: "Dashboard",
    component: <></>,
    path: "/",
    mainMenu: true,
    className: ACTIVE,
    icon: faDashboard,
    // role,
    component: <Dashboard crumbs="Dashboard"  />,
  },

  {
    name: "Vessel Requests",
    icon: faCircleCheck,
    path: "/vessel-requests",
    component: <PreApprovalList crumbs="Vessel/Vessel Requests" />,
    mainMenu: true,
  },
  {
    name: "Pre Approval Request Details",
    component: (
      <PreApprovalDetails crumbs="Vessel Requests/Vessel Request Details" />
    ),
    path: "/vessel-request-details",
  },
  {
    name: "Survey",
    icon: faClipboardList,
    path: "/survey-request",
    component: <SurveyRequestList crumbs="Survey/Survey Requests" />,
    mainMenu: true,
  },
  {
    name: "Survey Request Details",
    component: (
      <SurveyRequestDetails crumbs="Survey Request/ #Reference Number" />
    ),
    path: "/survey-request-details",
  },
  // {
  //   name: "Registration",
  //   icon: faUserPen,
  //   path: "/registration-requests",
  //   component: <RegistrationList crumbs="Registration/Registration Requests" />,
  //   mainMenu: true,
  // },
  // {
  //   name: "Registration Request Details",
  //   component: (
  //     <RegistrationDetails crumbs="Registration Request/ #Reference Number" />
  //   ),
  //   path: "/registration-request-details",
  // },
];
