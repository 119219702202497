import { lazy } from "react";
import {
  faDashboard,
  faCircleCheck,
  faClipboardList,
  faUserPen,
  faUserTie,
  faClipboard,
  // faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  ACTIVE,
  VO_PRE_APPROVAL,
  MY_PROFILE,
  vesselHistoryTypes,
} from "../utils/constants";
import PreApprovalList from "../pages/PreApproval/List/PreApprovalList";
import StageVerificationDetails from "../pages/StageVerification/Request";
//const PreApprovalList = lazy(() =>
// import("../pages/PreApproval/List/PreApprovalList")
//);
const PreApprovalDetails = lazy(() =>
  import("../pages/PreApproval/Request/PreApprovalRequestDetails")
);
const SurveyRequestList = lazy(() =>
  import("../pages/Survey/List/SurveyRequestList")
);
const SurveyRequestDetails = lazy(() =>
  import("../pages/Survey/Request/SurveyRequestDetails")
);
const RegistrationList = lazy(() =>
  import("../pages/Registration/List/RegistrationList")
);
const RegistrationDetails = lazy(() =>
  import("../pages/Registration/Request/RegistrationDetails")
);
const Dashboard = lazy(() => import("../pages/Dashboard"));

const StageVerificationList = lazy(() =>
  import("../pages/StageVerification/List")
);
const AddNewVessel = lazy(() =>
  import("../pages/VesselOwner/PreApproval/AddNewVessel")
);
const Registration = lazy(() => import("../pages/VesselOwner/Registration"));

const CarvingList = lazy(() => import("../pages/Carving/List/CarvingList"));
const CarvingDetails = lazy(() =>
  import("../pages/Carving/Request/CarvingDetails")
);
const MyProfile = lazy(() => import("../pages/Dashboard/myProfile"));

const VesselHistory = lazy(() =>
  import("../pages/VesselHistory/VesselHistory")
);

const CrewRegisteredVessels =lazy(() =>
  import("../pages/CrewManagement/CrewListVo/RegisteredVesselList")
);

const CrewManagement = lazy(() =>
  import("../pages/CrewManagement/CrewListVo/CrewManagement")
);

const StageVerificationKeyline = lazy(() =>
  import("../components/StageVerificationPreApproval")
);

// const ExistingVessel=lazy(() =>
//   import("../pages/VesselOwner/ExistingVessel/ExistingVessel")
// );

export const VesselOwnerRoutes = [
  {
    name: "Dashboard",
    path: "/",
    mainMenu: true,
    className: ACTIVE,
    icon: faDashboard,
    subMenuHead: false,
    component: <Dashboard crumbs="Dashboard" />,
  },
  {
    name: "New Request",
    component: (
      <PreApprovalList
        crumbs="Apply for New Vessel/New Request"
        requestTypeCode="new"
      />
    ),
    path: "/keel-laying-request",
  },
  {
    name: "New Request",
    component: (
      <PreApprovalList
        crumbs="Apply for New Vessel/New Request"
        requestTypeCode="new"
      />
    ),
    path: "/launching-request",
  },
  {
    name: "New Request",
    component: (
      <PreApprovalList
        crumbs="Apply for New Vessel/New Request"
        requestTypeCode="new"
      />
    ),
    path: "/stability-request",
  },
  {
    name: "New Request",
    component: (
      <PreApprovalList
        crumbs="Apply for New Vessel/New Request"
        requestTypeCode="new"
      />
    ),
    path: "/stage-verification-request",
  },
  {
    name: "Apply for New Vessel",
    icon: faCircleCheck,
    component: (
      <PreApprovalList
        crumbs="PreApproval/PreApproval Requests"
        requestTypeCode="new"
      />
    ),
    subMenuHead: false,
    path: "/new-request",
    childPaths: ["/new-request-add-vessel", "/new-request/vessel-history"],
    mainMenu: true,
  },

  {
    name: "Stage Verfication",
    // path: "/stage-verification-request",
    className: ACTIVE,
    icon: faDashboard,
    component: <Dashboard crumbs="Dashboard" />,
    subMenuHead: true,
    childrens: [
      {
        name: "Keel Laying",
        component: <StageVerificationList type={"Keel Laying"} />,
        path: "/keel-laying-requests",
        icon: faClipboard,
        childPaths: [
          "/stage-verification-keyline",
          "/keel-laying-requests/vessel-history",
        ],
      },
      {
        name: "Launching",
        component: <StageVerificationList type={"Launching"} />,
        path: "/launching-requests",
        icon: faClipboard,
        childPaths: [
          "/stage-verification-launching",
          "/launching-requests/vessel-history",
        ],
      },
      {
        name: "Stability",
        component: <StageVerificationList type={"Stability"} />,
        path: "/stability-requests",
        icon: faClipboard,
        childPaths: [
          "/stage-verification-stability",
          "/stability-requests/vessel-history",
        ],
      },
    ],
  },
  {
    component: (
      <StageVerificationDetails crumbs="New Vessel Requests/Stage Verification/Keel Laying" />
    ),
    name: "stage-verification-keel-laying",
    path: "/stage-verification-keel-laying",
  },
  {
    component: (
      <StageVerificationDetails crumbs="New Vessel Requests/Stage Verification/Launching" />
    ),
    name: "stage-verification-launching",
    path: "/stage-verification-launching",
  },
  {
    component: (
      <StageVerificationDetails crumbs="New Vessel Requests/Stage Verification/Stability" />
    ),
    name: "stage-verification-stability",
    path: "/stage-verification-stability",
  },
  {
    name: "Pre Approval Request Details",
    component: <PreApprovalDetails crumbs="Vessel/Vessel Request Details" />,
    path: "/pre-approval-request-details",
  },
  {
    name: "Survey",
    icon: faClipboardList,
    path: "/survey-request",
    component: <SurveyRequestList crumbs="Survey/Survey Application" />,
    mainMenu: true,
    childPaths: ["/survey-request-details", "/survey-request/vessel-history"],
  },
  {
    name: "Survey Request Details",
    component: (
      <SurveyRequestDetails crumbs="Survey Request/Survey Application" />
    ),
    path: "/survey-request-details",
  },
  {
    name: "Pre-registration",
    icon: faUserPen,
    path: "/registration-requests",
    component: <RegistrationList crumbs="Pre-registration/Pre-registration Requests" />,
    mainMenu: true,
    subMenuHead: true,
    childrens: [
      {
        name: "Registration",
        icon: faClipboardList,
        path: "/carving-requests",
        component: <CarvingList crumbs="Registration/Registration Requests" />,

        childPaths: [
          "/carving-request-details",
          "/carving-requests/vessel-history",
        ],
      },
    ],
    childPaths: [
      "/registration-request-details",
      "/registration-requests/vessel-history",
    ],
  },
  {
    name: "Registration",
    icon: faClipboardList,
    path: "/carving-requests",
    // mainMenu: true,
    component: <CarvingList crumbs="Registration/Registration Requests" />,
  },
  {
    name: "Registration Request Details",
    component: (
      <RegistrationDetails crumbs="Pre-registration Request/Pre-registration Requests Detail" />
    ),
    path: "/registration-request-details",
  },
  // {
  //   name: "Carving",
  //   icon: faClipboardList,
  //   path: "/carving-requests",
  //   component: <CarvingList crumbs="Registration/Carving Requests" />,
  //   mainMenu: true,
  //   childPaths: [
  //     "/carving-request-details",
  //     "/carving-requests/vessel-history",
  //   ],
  // },
  {
    name: "Registration Request Details",
    path: "/carving-request-details",
    component: <CarvingDetails crumbs="Registration Requests/ #Reference Number" />,
  },
  {
    name: "Crew Management",
    icon: faClipboardList,
    path: "/crew-management",
    component: <CrewRegisteredVessels crumbs="Crew Management/Crew List" />,
    mainMenu: true,
  },
  {
    name: "Crew Management",
    path: "/crew-management-details",
    component: <CrewManagement crumbs="Crew Management/Crew List" />,
  },
  
  {
    name: VO_PRE_APPROVAL,
    component: (
      <AddNewVessel
        crumbs="Apply for New Vessel/New Request/Add Vessel"
        requestTypeCode="new"
      />
    ),
    path: "/new-request-add-vessel",
  },
  {
    name: "Vessel History",
    path: "/new-request/vessel-history",
    component: (
      <VesselHistory
        crumbs="Apply for New Vessel / Vessel History"
        type={vesselHistoryTypes.PRE_APPROVAL}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/stage-verification-request/vessel-history",
    component: (
      <VesselHistory
        crumbs="Stage Verfication / Vessel History"
        type={vesselHistoryTypes.STAGE_VERIFICATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/keel-laying-request/vessel-history",
    component: (
      <VesselHistory
        crumbs="Stage Verfication / Keel Laying / Vessel History"
        type={vesselHistoryTypes.STAGE_VERIFICATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/launching-request/vessel-history",
    component: (
      <VesselHistory
        crumbs="Stage Verfication / Launching / Vessel History"
        type={vesselHistoryTypes.STAGE_VERIFICATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/stability-request/vessel-history",
    component: (
      <VesselHistory
        crumbs="Stage Verfication / Stability / Vessel History"
        type={vesselHistoryTypes.STAGE_VERIFICATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/survey-request/vessel-history",
    component: (
      <VesselHistory
        crumbs="Survey / Vessel History"
        type={vesselHistoryTypes.SURVEY}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/registration-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Pre-registration / Vessel History"
        type={vesselHistoryTypes.REGISTRATION}
      />
    ),
  },
  {
    name: "Vessel History",
    path: "/carving-requests/vessel-history",
    component: (
      <VesselHistory
        crumbs="Registration / Vessel History"
        type={vesselHistoryTypes.CARVING}
      />
    ),
  },
  {
    name: "stage verification",
    path: "/stage-verif",
    component: <StageVerificationKeyline crumbs="Carving / Vessel History" />,
  },
  // {
  //   name: "Add Exisiting Vessel",
  //   icon: faFileCirclePlus,
  //   path: "/add-existing-vessel",
  //   component: <ExistingVessel crumbs="Add Existing Vessel" />,
  //   mainMenu: true,
  // },
  // {
  //   name: "Add Exisiting Vessel",
  //   path: "/add-existing-vessel",
  //   component: <ExistingVessel crumbs="Add Existing Vessel" />,
  // },
];

export const RegistrationRoutes = [
  {
    name: "Registration",
    path: "/registration",
    icon: faDashboard,
    mainMenu: true,
    component: <Registration crumbs="Registration" />,
  },
  {
    name: "MY PROFILE",
    component: <MyProfile crumbs="My Profile" />,
    path: "/my-profile",
  },
];
