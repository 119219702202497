import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "./style.css";
import tickpick from "../assets/tick.png";
import warning from "../assets/warning.png"

const CommonModal = ({
  show,
  title,
  description,
  buttonText,
  handleClose,
  isSuccess
}) => {

  return (
    <>
      <Modal show={show} className="success-modal" onHide={handleClose}>
        <ModalHeader closeButton></ModalHeader>
        <ModalBody id="success-modal-body">
          {isSuccess ? (<img src={tickpick} />):(<img src={warning} className="common-warning-image"/>)}
          <div className="reg-first-text">{title}</div>
          <div className="reg-second-text">{description}</div>
          <div className="reg-btn-div">
            <button className="success-login-btn" onClick={handleClose}>
              {buttonText}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CommonModal;
