import React from "react";
import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { ADMIN, ADMIN_DASHBOARD } from "../utils/constants";
const PortsList = React.lazy(() => import("../pages/AdminModule/Ports"));
const AddPorts = React.lazy(() =>
  import("../pages/AdminModule/Ports/AddPorts")
);
const ViewPort = React.lazy(() =>
  import("../pages/AdminModule/Ports/ViewPort")
);

const AddRole = React.lazy(() => import("../pages/AdminModule/Roles/AddRoles"));
const ListRoles = React.lazy(() => import("../pages/AdminModule/Roles"));

const ViewRole = React.lazy(() =>
  import("../pages/AdminModule/Roles/ViewRole")
);
const AddDistrict = React.lazy(() =>
  import("../pages/AdminModule/Districts/AddDistricts")
);
const ListDistricts = React.lazy(() =>
  import("../pages/AdminModule/Districts")
);

const ViewDistrict = React.lazy(() =>
  import("../pages/AdminModule/Districts/ViewDistrict")
);
const Builder = React.lazy(() => import("../pages/AdminModule/Builder"));
const AddBuilder = React.lazy(() =>
  import("../pages/AdminModule/Builder/AddBuilder")
);
const ViewBuilder = React.lazy(() =>
  import("../pages/AdminModule/Builder/ViewBuilder")
);

const AddYard = React.lazy(() => import("../pages/AdminModule/Yard/AddYard"));

const AddUser = React.lazy(() => import("../pages/AdminModule/User/AddUser"));
const ListUser = React.lazy(() => import("../pages/AdminModule/User"));

const ViewUser = React.lazy(() => import("../pages/AdminModule/User/ViewUser"));

const AddSurveyType = React.lazy(() =>
  import("../pages/AdminModule/SurveyType/AddSurveyType")
);

const ViewSurveyType = React.lazy(() =>
  import("../pages/AdminModule/SurveyType/ViewSurveyType")
);

const ListSurveyType = React.lazy(() =>
  import("../pages/AdminModule/SurveyType")
);

const AddVesselType = React.lazy(() =>
  import("../pages/AdminModule/VesselType/AddVesselType")
);
const ViewVesselType = React.lazy(() =>
  import("../pages/AdminModule/VesselType/ViewVesselType")
);

const ListVesselType = React.lazy(() =>
  import("../pages/AdminModule/VesselType")
);

const AddVesselCategory = React.lazy(() =>
  import("../pages/AdminModule/VesselCategory/AddVesselCategory")
);
const ViewVesselCategory = React.lazy(() =>
  import("../pages/AdminModule/VesselCategory/ViewVesselCategory")
);

const ListVesselCategory = React.lazy(() =>
  import("../pages/AdminModule/VesselCategory")
);

const ViewYard = React.lazy(() =>
  import("../pages/AdminModule/Yard/ViewYard.js")
);

const ListYardType = React.lazy(() =>
  import("../pages/AdminModule/Yard/index.js")
);
const AddVesselSubType = React.lazy(() =>
  import("../pages/AdminModule/VesselSubType/AddVesselSubtype.js")
);
const ViewVesselSubType = React.lazy(() =>
  import("../pages/AdminModule/VesselSubType/ViewVesselSubType.js")
);

const ListVesselSubType = React.lazy(() =>
  import("../pages/AdminModule/VesselSubType/index.js")
);
const PreApprovalType = React.lazy(() =>
  import("../pages/AdminModule/PreApprovalType/index")
);
const AddPreApprovalType = React.lazy(() =>
  import("../pages/AdminModule/PreApprovalType/AddPreApprovalType")
);
const ViewPreApprovalType = React.lazy(() =>
  import("../pages/AdminModule/PreApprovalType/ViewPreApprovalType")
);
const PortofRegistry = React.lazy(() =>
  import("../pages/AdminModule/PortofRegistry/AddPortofReg.js")
);
const ViewPortofRegistry = React.lazy(() =>
  import("../pages/AdminModule/PortofRegistry/ViewPortofReg.js")
);
const ListPortofRegistry = React.lazy(() =>
  import("../pages/AdminModule/PortofRegistry/index.js")
);
const AddRates = React.lazy(() =>
  import("./../pages/AdminModule/Rates/AddRates")
);
const ListRates = React.lazy(() =>
  import("./../pages/AdminModule/Rates/index")
);
const ViewRates = React.lazy(() =>
  import("../pages/AdminModule/Rates/ViewRates.js")
);
const OffenceCategory = React.lazy(() =>
  import("../pages/AdminModule/OffenceCategory/index.js")
);
const AddOffenceCategory = React.lazy(() =>
  import("../pages/AdminModule/OffenceCategory/AddCategory.js")
);
const ViewOffenceCategory = React.lazy(() =>
  import("../pages/AdminModule/OffenceCategory/ViewOffenceCategory.js")
);
const OffenceSubCategory = React.lazy(() =>
  import("../pages/AdminModule/OffenceSubCategory/index.js")
);
const AddOffenceSubCategory = React.lazy(() =>
  import("../pages/AdminModule/OffenceSubCategory/AddCategory.js")
);
const ViewOffenceSubCategory = React.lazy(() =>
  import("../pages/AdminModule/OffenceSubCategory/ViewOffenceCategory.js")
);
const OffenceRule = React.lazy(() =>
  import("../pages/AdminModule/OffenceRule/index.js")
);
const AddOffenceRule = React.lazy(() =>
  import("../pages/AdminModule/OffenceRule/AddOffenceRule.js")
);
const ViewOffenceRule = React.lazy(() =>
  import("../pages/AdminModule/OffenceRule/ViewOffenceRule.js")
);
const OffenceNature = React.lazy(() =>
  import("../pages/AdminModule/OffenceNature/index.js")
);
const AddOffenceNature = React.lazy(() =>
  import("../pages/AdminModule/OffenceNature/AddOffenceNature.js")
);
const ViewOffenceNature = React.lazy(() =>
  import("../pages/AdminModule/OffenceNature/ViewOffenceNature.js")
);

const ListTaxTypes = React.lazy(() =>
  import("../pages/AdminModule/Taxes/index.js")
);

const AddTaxTypes = React.lazy(() =>
  import("../pages/AdminModule/Taxes/AddTax")
);

const ViewTaxTypes = React.lazy(() =>
  import("../pages/AdminModule/Taxes/ViewTax")
);

const VesselList = React.lazy(() => import("../pages/Vessels/List"));

export const AdminRoutes = [
  {
    name: ADMIN_DASHBOARD,
    icon: faDashboard,
    subMenuHead: true,
    childrens: [
      {
        name: "Ports",
        component: <PortsList crumbs="Admin/Ports" />,
        role: ADMIN,
        path: "/ports",
      },
      {
        name: "Districts",
        component: <ListRoles crumbs="Admin/Districts" />,
        role: ADMIN,
        path: "/list-districts",
      },
      {
        name: "Roles",
        component: <ListRoles crumbs="Admin/Roles" />,
        role: ADMIN,
        path: "/list-roles",
      },
      {
        name: "Users",
        component: <ListUser crumbs="Admin/Users" />,
        role: ADMIN,
        path: "/list-user",
      },
      {
        name: "Survey Type",
        component: <ListSurveyType crumbs="Admin/Survey type" />,
        role: ADMIN,
        path: "/survey-types",
      },
      {
        name: "Vessel Type",
        component: <ListVesselType crumbs="Admin/Vessels" />,
        role: ADMIN,
        path: "/list-vesseltype",
      },
      {
        name: "Vessel Subtype",
        component: <ListVesselSubType crumbs="Admin/Vessels" />,
        role: ADMIN,
        path: "/list-vesselsubtype",
      },
      {
        name: "Vessel Category",
        component: <ListVesselCategory crumbs="Admin/Vessels" />,
        role: ADMIN,
        path: "/list-vesselcategory",
      },
      {
        name: "PreApproval Type",
        component: <PreApprovalType crumbs="Admin/PreApproval-Type" />,
        role: ADMIN,
        path: "/list-pre-approval-type",
      },
      {
        name: "Builder",
        component: <ViewBuilder crumbs="Admin/Builder" />,
        role: ADMIN,
        path: "/list-builder",
      },
      {
        name: "Yard",
        component: <ListYardType crumbs="Admin/Yard" />,
        role: ADMIN,
        path: "/list-yard",
      },
      {
        name: "Port of Registry",
        component: <PortofRegistry crumbs="Admin/Port-of-Registry" />,
        role: ADMIN,
        path: "/list-port-of-reg",
      },
      {
        name: "Tax Types",
        component: <ListTaxTypes crumbs="Admin/Tax types" />,
        role: ADMIN,
        path: "/list-tax-types",
      },
      {
        name: "Rates",
        component: <ListRates crumbs="Admin/Rates" />,
        role: ADMIN,
        path: "/list-rates",
      },
      {
        name: "Vessel List",
        component: <VesselList crumbs="Admin/Rates" />,
        role: ADMIN,
        path: "/vessels-list",
        mainMenu: true,
        // childPaths: ["/vessels/vessel-history", "/vessels/vessels-details"],
      },
      {
        name: "Offence Category",
        component: <OffenceCategory crumbs="Admin/Offence Category" />,
        role: ADMIN,
        path: "/list-offence",
      },
      {
        name: "Offence Sub Category",
        component: <OffenceSubCategory crumbs="Admin/Offence Sub Category" />,
        role: ADMIN,
        path: "/list-offenceSub",
      },
      {
        name: "Offence Rule",
        component: <OffenceRule crumbs="Admin/Offence Rule" />,
        role: ADMIN,
        path: "/list-offenceRule",
      },
      {
        name: "Offence Nature",
        component: <OffenceNature crumbs="Admin/Offence Nature" />,
        role: ADMIN,
        path: "/list-offenceNature",
      },
    ],
  },
  {
    name: ADMIN_DASHBOARD,
    component: <PortsList crumbs="Admin/Ports" />,
    role: ADMIN,
    path: "/ports",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <AddPorts crumbs="Admin/Ports" />,
    role: ADMIN,
    path: "/add-ports",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <ViewPort crumbs="Admin/Ports" />,
    role: ADMIN,
    path: "/view-port",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <ListDistricts crumbs="Admin/Districts" />,
    role: ADMIN,
    path: "/list-districts",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <AddDistrict crumbs="Admin/Districts" />,
    role: ADMIN,
    path: "/add-district",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <ViewDistrict crumbs="Admin/Districts" />,
    role: ADMIN,
    path: "/view-district",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <ViewRole crumbs="Admin/Roles" />,
    role: ADMIN,
    path: "/view-role",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <ListRoles crumbs="Admin/Roles" />,
    role: ADMIN,
    path: "/list-roles",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <AddRole crumbs="Admin/Roles" />,
    role: ADMIN,
    path: "/add-roles",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <ViewRole crumbs="Admin/Roles" />,
    role: ADMIN,
    path: "/view-role",
  },
  {
    name: ADMIN_DASHBOARD,
    component: <ListUser crumbs="Admin/Users" />,
    role: ADMIN,
    path: "/list-user",
  },
  {
    name: "ADD_USER",
    component: <AddUser crumbs="Admin/Users" />,
    role: ADMIN,
    path: "/add-user",
  },

  {
    name: "VIEW_USER",
    component: <ViewUser crumbs="Admin/Users" />,
    role: ADMIN,
    path: "/view-user",
  },
  {
    name: "Survey Type",
    component: <AddSurveyType crumbs="Admin/Survey type" />,
    role: ADMIN,
    path: "/add-survey-type",
  },
  {
    name: "Survey Type",
    component: <ViewSurveyType crumbs="Admin/Survey type" />,
    role: ADMIN,
    path: "/view-survey-type",
  },
  {
    name: "Survey Type",
    component: <ListSurveyType crumbs="Admin/Survey type" />,
    role: ADMIN,
    path: "/survey-types",
  },
  {
    name: "ADD_VESSEL_TYPE",
    component: <AddVesselType crumbs="Admin/Vessel-Type" />,
    role: ADMIN,
    path: "/add-vesseltype",
  },
  {
    name: "VIEW_VESSEL_TYPE",
    component: <ViewVesselType crumbs="Admin/Vessel-Type" />,
    role: ADMIN,
    path: "/view-vesseltype",
  },
  {
    name: "LIST_VESSEL_TYPE",
    component: <ListVesselType crumbs="Admin/Vessel-Type" />,
    role: ADMIN,
    path: "/list-vesseltype",
  },
  {
    name: "ADD_VESSEL_CATEGORY",
    component: <AddVesselCategory crumbs="Admin/Vessel-Category" />,
    role: ADMIN,
    path: "add-vesselcategory",
  },

  {
    name: "VIEW_VESSEL_CATEGORY",
    component: <ViewVesselCategory crumbs="Admin/Vessel-category" />,
    role: ADMIN,
    path: "/view-vesselcategory",
  },
  {
    name: "LIST_VESSEL_CATEGORY",
    component: <ListVesselCategory crumbs="Admin/Vessel-Category" />,
    role: ADMIN,
    path: "/list-vesselcategory",
  },

  {
    name: "Pre_Approval_Type",
    component: <PreApprovalType crumbs="Admin/PreApproval-Type" />,
    role: ADMIN,
    path: "/list-pre-approval-type",
  },
  {
    name: "Pre_Approval_Type",
    component: <AddPreApprovalType crumbs="Admin/PreApproval-Type" />,
    role: ADMIN,
    path: "/add-pre-approval-type",
  },
  {
    name: "Pre_Approval_Type",
    component: <ViewPreApprovalType crumbs="Admin/PreApproval-Type" />,
    role: ADMIN,
    path: "/view-pre-approval-type",
  },
  {
    name: "Offence Category",
    component: <OffenceCategory crumbs="Admin/Offenece Category" />,
    role: ADMIN,
    path: "/list-offence",
  },
  {
    name: "Offence Category",
    component: <AddOffenceCategory crumbs="Admin/Offenece Category" />,
    role: ADMIN,
    path: "/add-offence-category",
  },
  {
    name: "Offence Category",
    component: <ViewOffenceCategory crumbs="Admin/Offenece Category" />,
    role: ADMIN,
    path: "/view-offence-category",
  },
  {
    name: "Offence Sub Category",
    component: <OffenceSubCategory crumbs="Admin/Offence Sub Category" />,
    role: ADMIN,
    path: "/list-offenceSub",
  },
  {
    name: "Offence Category",
    component: <AddOffenceSubCategory crumbs="Admin/Offence Category" />,
    role: ADMIN,
    path: "/add-offence-subcategory",
  },
  {
    name: "Offence Category",
    component: <ViewOffenceSubCategory crumbs="Admin/Offence Category" />,
    role: ADMIN,
    path: "/view-offence-subcategory",
  },
  {
    name: "Offence Rule",
    component: <OffenceRule crumbs="Admin/Offence Rule" />,
    role: ADMIN,
    path: "/list-offenceRule",
  },
  {
    name: "Offence ",
    component: <AddOffenceRule crumbs="Admin/Offence Rule" />,
    role: ADMIN,
    path: "/add-offence-rule",
  },
  {
    name: "Offence ",
    component: <ViewOffenceRule crumbs="Admin/Offence Rule" />,
    role: ADMIN,
    path: "/view-offence-rule",
  },
  {
    name: "Offence Rule",
    component: <OffenceNature crumbs="Admin/Offence Nature" />,
    role: ADMIN,
    path: "/list-offenceNature",
  },
  {
    name: "Offence ",
    component: <AddOffenceNature crumbs="Admin/Offence Nature" />,
    role: ADMIN,
    path: "/add-offence-nature",
  },
  {
    name: "Offence ",
    component: <ViewOffenceNature crumbs="Admin/Offence Nature" />,
    role: ADMIN,
    path: "/view-offence-nature",
  },

  {
    name: "ADD_BUILDER",
    component: <AddBuilder crumbs="Admin/Builder" registrationNeeded={false} />,
    role: ADMIN,
    path: "/add-builder",
  },
  {
    name: "VIEW_BUILDER",
    component: <Builder crumbs="Admin/Builder" />,
    role: ADMIN,
    path: "/list-builder",
  },
  {
    name: "LIST_BUILDER",
    component: <ViewBuilder crumbs="Admin/Yard" />,
    role: ADMIN,
    path: "/view-builder",
  },
  {
    name: "ADD_YARD",
    component: <AddYard crumbs="Admin/Yard" registrationNeeded={false} />,
    role: ADMIN,
    path: "/add-yard",
  },
  {
    name: "VIEW_YARD",
    component: <ViewYard crumbs="Admin/Yard" />,
    role: ADMIN,
    path: "/view-yard",
  },
  {
    name: "LIST_YARD",
    component: <ListYardType crumbs="Admin/Yard" />,
    role: ADMIN,
    path: "/list-yard",
  },
  {
    name: "PORT_OF_REGISTRY",
    component: <PortofRegistry crumbs="Admin/Port-of-Registry" />,
    role: ADMIN,
    path: "/add-port-of-reg",
  },
  {
    name: "VIEW_PORT_OF_REGISTRY",
    component: <ViewPortofRegistry crumbs="Admin/Port-of-Registry" />,
    role: ADMIN,
    path: "/view-port-of-reg",
  },
  {
    name: "LIST_PORT_OF_REGISTRY",
    component: <ListPortofRegistry crumbs="Admin/Port-of-Registry" />,
    role: ADMIN,
    path: "/list-port-of-reg",
  },
  {
    name: "ADD_RATES",
    component: <AddRates crumbs="Admin/Rates" />,
    role: ADMIN,
    path: "/add-rates",
  },
  {
    name: "LIST_RATES",
    component: <ListRates crumbs="Admin/Rates" />,
    role: ADMIN,
    path: "/list-rates",
  },
  {
    name: "VIEW_RATES",
    component: <ViewRates crumbs="Admin/Rates" />,
    role: ADMIN,
    path: "/view-rates",
  },
  {
    name: "ADD_VESSEL_SUB_TYPES",
    component: <AddVesselSubType crumbs="Admin/Vessels" />,
    role: ADMIN,
    path: "/add-vesselsubtype",
  },
  {
    name: "VIEW_VESSEL_SUB_TYPES",
    component: <ViewVesselSubType crumbs="Admin/Vessels" />,
    role: ADMIN,
    path: "/view-vesselsubtype",
  },
  {
    name: "LIST_VESSEL_SUB_TYPES",
    component: <ListVesselSubType crumbs="Admin/Vessels" />,
    role: ADMIN,
    path: "/list-vesselsubtype",
  },
  {
    name: "Tax Types",
    component: <ListTaxTypes crumbs="Admin/Tax types" />,
    role: ADMIN,
    path: "/list-tax-types",
  },
  {
    name: "Tax Types",
    component: <AddTaxTypes crumbs="Admin/Tax types/Add Tax Type" />,
    role: ADMIN,
    path: "/add-tax-type",
  },
  {
    name: "Tax Types",
    component: <ViewTaxTypes crumbs="Admin/Tax types/View Tax Type" />,
    role: ADMIN,
    path: "/view-tax-type",
  },
  {
    name: "Vessel List",
    component: <VesselList crumbs="Admin/Vessels" adminVesselList={true} />,
    role: ADMIN,
    path: "/vessels-list",

    // childPaths: ["/vessels/vessel-history", "/vessels/vessels-details"],
  },
];
