import { pagination } from "../../../../utils/constants";

export const addVesselType = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel-type`,
      method: "POST",
      body: data,
    }),
  });

export const fetchVesselTypes = (build) => {
  return build.query({
    query: ({ searchParams = "", page = "" }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/vessel-type?${params.join("&")}`;
      return url;
    },
    providesTags: ["VesselTypes"],
  });
};

export const fetchVesselBuilder = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);

      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/vessel-builder?${params.join("&")}`;

      return url;
    },
  });
};
export const fetchVesselTypebyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/vessel-type/${id}`;
      return url;
    },
  });
};
export const updateVesselType = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `vessel-type/${id}`,
      method: "PATCH",
      body: data,
    }),
    invalidatesTags: ["VesselTypes"],
  });
export const deleteVesselType = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `vessel-type/${id}`,
      method: "DELETE",
    }),
  });

export const addVesselCategory = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel-category`,
      method: "post",
      body: data,
    }),
  });

export const fetchVesselCategory = (build) => {
  return build.query({
    query: ({ searchParams = "", page = "" }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `/vessel-category?${params.join("&")}`;
      return url;
    },
    providesTags: ["VesselCategories"],
  });
};
export const fetchVesselCategorybyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/vessel-category/${id}`;
      return url;
    },
  });
};
export const updateVesselCategory = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `vessel-category/${id}`,
      method: "PATCH",
      body: data,
    }),
    invalidateTags: ["VesselCategories"],
  });
export const deleteVesselCategory = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `vessel-category/${id}`,
      method: "DELETE",
    }),
  });

export const fetchValidBuilder = (build) => {
  return build.query({
    query: ({ type }) => {
      const url = `/vessel-builder/valid`;
      return url;
    },
  });
};

export const fetchVesselBuilderbyId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `/vessel-builder/${id}`;
      return url;
    },
  });
};
export const updateVesselBuilder = (build) =>
  build.mutation({
    query: ({ data, id }) => ({
      url: `vessel-builder/${id}`,
      method: "PATCH",
      body: data,
    }),
  });
export const deleteVesselBuilder = (build) =>
  build.mutation({
    query: ({ id }) => ({
      url: `vessel-builder/${id}`,
      method: "DELETE",
    }),
  });

// export const addVesselBuilder = (build) =>
//   build.mutation({
//     query: ({email = "", role="",mobile="",data}) => {
//       console.log("api",email,role,mobile,data);
//       const params = [];
//       if (email) {
//         params.push(`email=${email}`);
//       }
//       if(role){
//         params.push(`role=${role}`);
//       }
//       if(mobile){
//         params.push(`mobile=${mobile}`);
//       }
//       const url = `/vessel-builder${
//         params.length ? `?${params.join("&")}` : ""
//       }`;
//       return {
//         url,
//         method: "post",
//         body: data,
//       };
//     },
//   });

export const addVesselBuilder = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel-builder`,
      method: "post",
      body: data,
    }),
  });

export const addVesselBuilderFromAdmin = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/vessel-builder/fromAdmin`,
      method: "post",
      body: data,
    }),
  });

export const fetchVesselListForAdmin = (build) => {
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }
      const url = `vessel/list/alluseradmin?${params.join("&")}`;
      return url;
    },
  });
};
