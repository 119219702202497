import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import SuccessModal from "../CommonModal";
import "./theme.scss";

function Sidemenu({ routes }) {
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const elementRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState("");
  // const [useActive, setUseActive] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const isMenuActive = (menu) => {
    return currentPath === menu.path &&
      (!menu.childrens || menu.childrens.length === 0)
      ? "active"
      : "";
  };  

  const toggleSideCollapsed = useCallback(() => {
    if (
      elementRef.current &&
      elementRef.current.classList.contains("ps-collapsed")
    ) {
      document.body.classList.remove("body-collapsed");
    } else {
      document.body.classList.add("body-collapsed");
    }
    setSideCollapsed((collapsed) => !collapsed);
  }, [setSideCollapsed]);

  useEffect(() => {
    const firstMenu = routes[0];
    if (firstMenu) {
      setActiveMenu(firstMenu.name);
    }
  }, []);

  return (
    <>
      {/* <SuccessModal />   */}
      <Sidebar
        collapsed={sideCollapsed}
        collapsedWidth="100p"
        rootStyles={{
          border: "none",
          top: 0,
          height: "100vh",
          position: "fixed",
          overflow: "auto",
        }}
        ref={elementRef}
      >
        <div className="app-brand demo">
          <img
            src="../../../assets/icons/collapse_menu.svg"
            onClick={toggleSideCollapsed}
            className="collapsed-icon"
          />
          <a href="#" className="app-brand-link">
            <span className="app-brand-logo demo">
              <img src="../../../assets/images/logo-dashboard.svg" />
            </span>
            {/* <span className="app-brand-text demo menu-text fw-bolder ms-2">KMB</span> */}
          </a>
        </div>

        <Menu>
          {routes &&
            routes.map((menu, i) => (
              <div onClick={() => console.log(menu, "IPO")} key={menu.path + i}>
                {menu.mainMenu && !menu.subMenuHead && (
                  <MenuItem
                    icon={<FontAwesomeIcon icon={menu.icon} />}
                    component={<Link to={menu.path} />}
                    className={isMenuActive(menu)}
                    onClick={() => handleMenuClick(menu.name)}
                  >
                    {menu.name}
                  </MenuItem>
                )}

                {menu.subMenuHead && (
                  <Link
                    to={menu.path}
                    onClick={() => console.log(menu.path, "IPPP")}
                    style={{ textDecoration: "none" }}
                  >
                    <SubMenu
                      label={menu.name}
                      icon={<FontAwesomeIcon icon={menu.icon} />}
                      className={isMenuActive(menu)}
                    >
                      {menu.childrens.length > 0 &&
                        menu.childrens.map((el, i) => (
                          <Fragment key={i}>
                            {el.child ? (
                              <SubMenu
                                label={el.name}
                                // icon={<FontAwesomeIcon icon={menu.icon} />}
                              >
                                {el.childrens.map((ele) => (
                                  <MenuItem
                                    key={ele.path + i}
                                    component={<Link to={ele.path} />}
                                    className={isMenuActive(ele)}
                                    icon={
                                      el.icon ? (
                                        <FontAwesomeIcon icon={el.icon} />
                                      ) : (
                                        <></>
                                      )
                                    }
                                  >
                                    {ele.name}
                                  </MenuItem>
                                ))}
                              </SubMenu>
                            ) : (
                              <MenuItem
                                key={el.path + i}
                                component={<Link to={el.path} />}
                                className={isMenuActive(el)}
                                icon={
                                  el.icon ? (
                                    <FontAwesomeIcon icon={el.icon} />
                                  ) : (
                                    <></>
                                  )
                                }
                              >
                                {el.name}
                              </MenuItem>
                            )}
                          </Fragment>
                        ))}
                    </SubMenu>
                  </Link>
                )}
              </div>
            ))}
        </Menu>
      </Sidebar>
    </>
  );
}

export default Sidemenu;
