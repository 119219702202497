import "./App.css";
import "./styles.scss";

import { Route, Routes } from "react-router-dom";
import AppRoutes from "./routes";
function App() {
  return <AppRoutes />;
}

export default App;
