import { Table } from "react-bootstrap";
import "./style.scss";
import { MdDelete } from "react-icons/md";
import { ErrorMessage } from "formik";
import TextBox from "./TextBox";
import SelectBox from "./Selectbox";

function CommonInputTable({
  fieldName,
  rowHead,
  values,
  fields,
  onChange,
  onDelete,
  validation,
  isDisabled,
  required = true,
}) {
  if (!values) console.warn("Values are empty");

  return (
    <Table className="common-list-table rounded-4 col-md-6" striped hover>
      <TableHead rowHead={rowHead} required={required} />
      <TableBody
        values={values}
        fields={fields}
        fieldName={fieldName}
        onChange={onChange}
        onDelete={onDelete}
        validation={validation}
        isDisabled={isDisabled}
      />
    </Table>
  );
}

function TableHead({ rowHead, required }) {
  return (
    <thead>
      <tr>
        {rowHead.map((data, key) => (
          <th key={key} className="p-3 border-0 table-list-heading">
            {data.heading}
            {required && <span className="required-indicator"></span>}
          </th>
        ))}
        <th></th>
      </tr>
    </thead>
  );
}

function TableBody({
  values,
  fields,
  fieldName,
  onChange,
  onDelete,
  validation,
  isDisabled,
}) {
  return (
    <tbody>
      {values &&
        values.map((value, index) => (
          <TableRow
            key={index}
            cols={value}
            index={index}
            fields={fields}
            fieldName={fieldName}
            onChange={onChange}
            onDelete={onDelete}
            validation={validation}
            showDelete={values.length > 1}
            isDisabled={isDisabled}
          />
        ))}
    </tbody>
  );
}

function TableRow({
  fields,
  cols,
  index,
  fieldName,
  onChange,
  onDelete,
  validation,
  showDelete,
  isDisabled,
}) {
  function getFileName(fileName) {
    if (fileName) {
      if (fileName instanceof File) {
        return `Choose file: ${fileName.name}`;
      } else {
        return "Choose file: Uploaded File";
      }
    } else {
      return "Choose file: No file chosen";
    }
  }

  return (
    <tr>
      <td className="align-middle text-center">{index + 1}</td>
      {fields.map(({ name, ...props }, key) => (
        <td key={key}>
          {props?.type === "file" ? (
            <div className="position-relative">
              <TextBox
                className="text-input form-control m-1 opacity-0 pe-none"
                {...props}
                type={"file"}
                id={`${fieldName}[${index}].${name}`}
                value={undefined}
                name={`${fieldName}[${index}].${name}`}
                onChange={onChange}
                touched={validation.touched[`${fieldName}[${index}].${name}`]}
                error={validation.errors[`${fieldName}[${index}].${name}`]}
                isDisabled={isDisabled}
              />
              <label
                className={`text-input form-control mt-0 position-absolute top-0 bottom-0 start-0 end-0 d-inline-flex align-items-center ${
                  isDisabled ? "bg-body-secondary" : ""
                }`}
                role={!isDisabled && "button"}
                htmlFor={`${fieldName}[${index}].${name}`}
                isDisabled={isDisabled}
                name={`${fieldName}[${index}].${name}`}
              >
                {isDisabled ? "File" : getFileName(cols[name])}
              </label>
            </div>
          ) : props?.type === "select" ? (
            <SelectBox
              label={name}
              labelHide={true}
              id={`${fieldName}[${index}].${name}`}
              name={`${fieldName}[${index}].${name}`}
              options={props?.options}
              value={cols[name]}
              isRequired={true}
              isIdSelect={true}
              onChange={onChange}
              error={validation.errors[`${fieldName}[${index}].${name}`]}
              isTouched={validation.touched[`${fieldName}[${index}].${name}`]}
              isDisabled={isDisabled}
            />
            
          ) : (
            <TextBox
              className="text-input form-control m-1"
              {...props}
              name={`${fieldName}[${index}].${name}`}
              value={cols[name]}
              onChange={onChange}
              touched={validation.touched[`${fieldName}[${index}].${name}`]}
              error={validation.errors[`${fieldName}[${index}].${name}`]}
              isDisabled={isDisabled}
            />
          )}
          <ErrorMessage
            name={`${fieldName}[${index}].${name}`}
            render={(msg) => (
              <div className="error-message h-auto w-auto">{msg}</div>
            )}
          />
        </td>
      ))}

      <td className="align-middle text-center">
        {!isDisabled && showDelete ? (
          <button
            className="border-0 bg-transparent"
            type="button"
            onClick={() => onDelete(index)}
          >
            <MdDelete size={"1.4rem"} color="red" />
          </button>
        ) : null}
      </td>
    </tr>
  );
}

export default CommonInputTable;
