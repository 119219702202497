import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  error: false,
  showError: false,
  message: "",
  loading: false,
};

const LoadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    showError(state, { payload }) {
      state.error = true;
      state.message = payload?.data?.message || payload?.error?.data?.message;
      state.showError = true;
    },
    hideError(state) {
      state.error = false;
      state.message = "";
      state.showError = false;
    },
    setResponse(state, { payload }) {
      if (payload?.error?.data) {
        state.error = true;
        state.message = payload?.error?.data?.message;
        state.showError = true;
      } else {
        state.message = payload?.data?.message;
        state.error = false;
        state.showError = false;
      }
    },
  },
});

export const { showError, hideError, setResponse } = LoadingSlice.actions;
export default LoadingSlice.reducer;
