import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  fetchPreApprovalTypes,
  fetchPreApprovalTypebyId,
  addPreApprovalType,
  updatePreApprovalType,
  deletePreApprovalType,
} from "./PreApprovalTypes";

export const PreApprovalTypesApi = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    fetchPreApprovalTypes: fetchPreApprovalTypes(build),
    fetchPreApprovalTypebyId: fetchPreApprovalTypebyId(build),
    addPreApprovalType: addPreApprovalType(build),
    updatePreApprovalType: updatePreApprovalType(build),
    deletePreApprovalType: deletePreApprovalType(build),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchPreApprovalTypesQuery,
  useLazyFetchPreApprovalTypebyIdQuery,
  useAddPreApprovalTypeMutation,
  useUpdatePreApprovalTypeMutation,
  useDeletePreApprovalTypeMutation,
} = PreApprovalTypesApi;
