import {
  faDashboard,
  faFileCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { lazy } from "react";
import { ACTIVE } from "../utils/constants";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const ExistingVesselList = lazy(() =>
  import("../pages/ExistingVessel/List/ExistingVesselList")
);

const ExistingVessel = lazy(() =>
  import("../pages/ExistingVessel/Request/ExistingVessel")
);

export const DataEntryOperatorRoutes = [
  {
    name: "Dashboard",
    path: "/",
    mainMenu: true,
    className: ACTIVE,
    icon: faDashboard,
    subMenuHead: false,
    component: <Dashboard crumbs="Dashboard" />,
  },
  {
    name: "Add Exisiting Vessel",
    icon: faFileCirclePlus,
    component: (
      <ExistingVesselList
        crumbs="Existing Vessel"
        
      />
    ),
    subMenuHead: false,
    path: "/existing-vessel",
    childPaths: ["/existing-vessel-requests"],
    mainMenu: true,
  },
  {
    name: "Add Exisiting Vessel",
    path: "/existing-vessel-requests",
    component: (
      <ExistingVessel
         crumbs="Existing Vessel"
        
      />
    ),
  },
];
