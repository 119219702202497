import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { InputGroup } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

function SearchFilter({
  searchFilters = {},
  onFilterChange = () => {},
  onValueChange = () => {},
  searchFilter = "",
  placeholder = "",
}) {
  const debouncedResults = useMemo(() => {
    const handleSearchChange = (el) => {
      onValueChange(el.target.value);
    };
    return debounce(handleSearchChange, 1000);
  }, [onValueChange]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  // Example Filter Options
  // const filters = {
  //   valueSearch: {
  //     value: "valueSearch",
  //     label: "Value Search Label",
  //     type: "search", /* Normal text/search */
  //   },
  //   valueSelect: {
  //     value: "valueSelect",
  //     label: "Value Select Label",
  //     type: "select", /* Dropdown list */
  //     typeMetadata: {
  //       options: { Select1: "", Select2: true, Select3: false },
  //     },
  //   },
  //   valueDate: {
  //     label: "valueDate",
  //     value: "Value Date Label",
  //     type: "date", /* Date input */
  //   },
  // };

  return (
    <div className="px-3 d-flex gap-3 col-md-6 mb-3">
      <Form.Select
        className="text-input mt-2 w-50"
        onChange={onFilterChange}
        value={searchFilter}
      >
        {Object.entries(searchFilters).map(([keyName, value]) => (
          <option key={keyName} value={value.value}>
            {value.label}
          </option>
        ))}
      </Form.Select>
      {
        <Col className="w-50">
          <InputGroup>
            {searchFilters?.[searchFilter]?.type === "select" ? (
              <Form.Select
                placeholder={placeholder ? placeholder : "Search"}
                onChange={(e) => onValueChange(e.target.value)}
                className="table-search text-input mt-2 start-0 top-0"
                key={searchFilter}
              >
                {Object.entries(
                  searchFilters?.[searchFilter]?.typeMetadata?.options
                ).map(([label, value]) => (
                  <option label={label} value={value} />
                ))}
              </Form.Select>
            ) : (
              <>
                <Form.Control
                  placeholder={placeholder ? placeholder : "Search"}
                  onChange={debouncedResults}
                  className="table-search top-0"
                  aria-describedby="basic-addon2"
                  type={searchFilters?.[searchFilter]?.type}
                  key={searchFilter}
                />
                {["text", "search"].includes(
                  searchFilters?.[searchFilter]?.type
                ) ? (
                  <img
                    src="../../../assets/icons/search.svg"
                    className="table-search-icon pe-none"
                    alt="search"
                  />
                ) : null}
              </>
            )}
          </InputGroup>
        </Col>
      }
    </div>
  );
}

SearchFilter.propTypes = {
  searchFilters: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  searchFilter: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SearchFilter;
