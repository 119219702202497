import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  fetchDistricts,
  fetchDistrictById,
  updateDistrict,
  addDistrict,
  deleteDistrict,
} from "./District";

export const DistrictDetailAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    fetchDistricts: fetchDistricts(build),
    fetchDistrictById: fetchDistrictById(build),
    updateDistrict: updateDistrict(build),
    addDistrict: addDistrict(build),
    deleteDistrict: deleteDistrict(build),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchDistrictsQuery,
  useLazyFetchDistrictByIdQuery,
  useUpdateDistrictMutation,
  useAddDistrictMutation,
  useDeleteDistrictMutation,
} = DistrictDetailAPI;
