import { Accordion, Col, Row } from "react-bootstrap";
import { formatDate } from "../../utils/utility";
import { useLazyFetchSurveyTypebyIdQuery } from "../../services/modules/admin/SurveyTypes";
import { useEffect } from "react";

function SurveyDetails({ additionalData = {}, vesselData = {} }) {
  const surveyTypeId = vesselData?.surveyType?._id;

  const [fetchSurveyTypebyId, { data, isSuccess }] =
    useLazyFetchSurveyTypebyIdQuery();

  useEffect(() => {
    if (surveyTypeId) fetchSurveyTypebyId({ id: surveyTypeId });
  }, [surveyTypeId, fetchSurveyTypebyId]);

  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>Survey Application</Accordion.Header>
      <Accordion.Body>
        <h4 className="my-3">Survey Details</h4>
        <Row className="d-flex">
          <Row>
            <Col md={4} className="mb-3">
              <p className="field ">Survey Type</p>
              <p className="display-field fw-bold">
                {vesselData?.surveyType?.surveyName}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Registered Tonnage </p>
              <p className="display-field fw-bold">
                {additionalData?.regTonnage}
              </p>
            </Col>
            <Col md={4}>
              <p className="field ">Year of Build</p>
              <p className="display-field fw-bold">
                {additionalData?.yearOfBuild}
              </p>
            </Col>
            {isSuccess &&
            data?.result?.surveyCode?.toLowerCase()?.trim() !== "initialsurvey" ? (
              <>
                <Col md={4}>
                  <p className="field ">Date of Last Survey</p>
                  <p className="display-field fw-bold">
                    {formatDate(additionalData?.dateOfLastSurvey)}
                  </p>
                </Col>
                <Col md={4}>
                  <p className="field ">Place of Last Survey</p>
                  <p className="display-field fw-bold">
                    {additionalData?.placeOfLastSurvey}
                  </p>
                </Col>
              </>
            ) : null}
          </Row>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default SurveyDetails;
