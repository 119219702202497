import React from "react";

const AddYard = React.lazy(() => import("../pages/AdminModule/Yard/AddYard"));
const ViewYard = React.lazy(() =>
  import("../pages/AdminModule/Yard/ViewYard.js")
);

const ListYardType = React.lazy(() =>
  import("../pages/AdminModule/Yard/index.js")
);

export const YardRoutes = [
  {
    name: "YARD",
    component: <ListYardType crumbs="Yard" />,
    //  role: builder,
    mainMenu: true,
    path: "/list-yard",
  },
  {
    name: "ADD_YARD",
    component: <AddYard crumbs="Yard/Add" />,
    path: "/add-yard",
  },
  {
    name: "VIEW_YARD",
    component: <ViewYard crumbs="Yard/View" />,
    path: "/view-yard",
  },
  {
    name: "LIST_YARD",
    component: <ListYardType crumbs="Yard/List" />,
    path: "/list-yard",
  },
];
