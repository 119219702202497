import React from 'react'
import './style.css'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CommonPageTitle = ({title,handleClickBack,arrowback}) => {
  return (
    <div className='common-title'>
     {arrowback &&<FontAwesomeIcon icon={faArrowLeft} onClick={handleClickBack} style={{cursor:"pointer"}}/>} {title}
    </div>
  )
}

export default CommonPageTitle